import React, { useEffect, useRef, useState } from 'react';
import {
	getCustomerFiles,
	openPDFAsync
} from './API_Functions/UserFunctions';
import {
	getChemicalLoads,
	getChemicals
} from './API_Functions/ChemicalFunctions';
import { Progress } from 'reactstrap';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Autosuggest from 'react-autosuggest';
import { getFormattedDate } from '../utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import { getCustomers } from './API_Functions/CustomerFunctions';
import { useAlert, positions } from 'react-alert';
import {
	faAngleDown,
	faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	deleteSDSFiles,
	updateAndUploadSDSFiles,
	uploadFiles
} from './API_Functions/SDSFunctions';
import UploadResults from './modals/UploadResults';

const escapeRegexCharacters = (str) => {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const Regulatory = () => {
	const [products, setProducts] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState('');
	const [selectedChemical, setSelectedChemical] = useState('');
	const [selectedLoad, setSelectedLoad] = useState('');
	const [customers, setCustomers] = useState([]);
	const [chemicals, setChemicals] = useState([]);
	const [loads, setLoads] = useState([]);
	const [uniqueInputKey, setUniqueInputKey] = useState(Date.now());
	const [loaded, setLoaded] = useState(0);
	const [error, setError] = useState('');
	const [draggedOver, setDraggedOver] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [filesToDelete, setFilesToDelete] = useState([]);
	const [checkedFiles, setCheckedFiles] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionValue, setSuggestionValue] = useState('');
	const [duplicateError, setDuplicateError] = useState(false);
	const [duplicateMsg, setDuplicateMsg] = useState('');
	const [gettingCustomers, setGettingCustomers] = useState(false);
	const [openUploadResults, setOpenUploadResults] = useState(false);
	const [uploadResultsData, setUploadResultsData] = useState({});

	const alert = useAlert();

	const autosuggestInput = useRef(null);

	useEffect(() => {
		(async () => {
			try {
				setCustomers(
					(await getCustomers()).data.customers
						.map((v) => v.customer)
						.sort()
				);
			} catch (e) {
				console.log(e);
			}
		})();
	}, []);

	const getFilesFromDB = (chemical = null, load = null) => {
		getCustomerFiles(selectedCustomer, chemical, load)
			.then((r) => {
				const files = r.data.files;
				files.sort((a, b) => {
					let sortResult = 0;
					if (a.active < b.active) sortResult = 1;
					if (a.active > b.active) sortResult = -1;
					if (a.sheet_name > b.sheet_name) sortResult = 1;
					if (a.sheet_name < b.sheet_name) sortResult = -1;
					return sortResult;
				});
				const filenames = files.map((file) => ({
					sheet_file_name: file.sheet_file_name,
					sheet_name: file.sheet_name,
					chemical: file.chemical,
					load: file.load,
					brand: file.brand,
					updated: file.updated_at,
					active: file.active
				}));
				setProducts(filenames);
			})
			.catch((e) =>
				console.log('Error getting the customer files: ', e)
			);
	};

	useEffect(() => {
		setGettingCustomers(true);
		setSelectedChemical('');
		setSelectedLoad('');
		setChemicals([]);
		setLoads([]);

		if (selectedCustomer) {
			(async () => {
				await getFilesFromDB();
				if (selectedCustomer !== 'Core') {
					console.log({ selectedCustomer });
					await getChemicals(selectedCustomer).then((r) => {
						console.log('chemicals: ', r.data.chemicals);
						setChemicals(r.data.chemicals);
					});
					setGettingCustomers(false);
				} else {
					// it is a core item, set chemical and load
					setSelectedChemical('Core');
					setSelectedLoad('Core');
					setGettingCustomers(false);
				}
			})();
		} else {
			setGettingCustomers(false);
		}
	}, [selectedCustomer]);

	useEffect(() => {
		if (suggestionValue === '') {
			setSelectedChemical('');
			setSelectedLoad('');
			setChemicals([]);
			setLoads([]);
			setProducts([]);
		}
	}, [suggestionValue]);

	const customerSuggestionSelected = (event, { suggestion }) => {
		setSelectedCustomer(suggestion);
	};

	// Teach Autosuggest how to calculate suggestions for any given input value.
	const getSuggestions = (value) => {
		const inputValue = value
			? escapeRegexCharacters(value.trim().toLowerCase())
			: '';

		const inputLength = inputValue.length;

		const regex = new RegExp('^' + inputValue, 'i');

		return inputLength === 0
			? customers
			: customers.filter((customer) =>
					//customer.toLowerCase().slice(0, inputLength) === inputValue
					regex.test(customer.toLowerCase())
			  );
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const getSuggestionValue = (suggestion) => {
		return suggestion;
	};

	const onChangeSuggestion = (event, { newValue, method }) => {
		setSuggestionValue(newValue);
	};

	function renderSuggestion(suggestion, { query }) {
		const matches = AutosuggestHighlightMatch(suggestion, query);
		const parts = AutosuggestHighlightParse(suggestion, matches);

		return (
			<span>
				{parts.map((part, index) => {
					const className = part.highlight
						? 'react-autosuggest__suggestion-match'
						: null;

					return (
						<span className={className} key={index}>
							{part.text}
						</span>
					);
				})}
			</span>
		);
	}

	const shouldRenderSuggestions = () => {
		return true;
	};

	const renderInputComponent = (inputProps) => (
		<div className="inputContainer">
			{selectedCustomer ? (
				<FontAwesomeIcon
					icon={faTimes}
					size={'1x'}
					onClick={() => {
						setSelectedCustomer('');
						setSuggestionValue('');
					}}
				/>
			) : (
				<FontAwesomeIcon
					icon={faAngleDown}
					size={'1x'}
					// onClick={() => autosuggestInput.current.focus()}
				/>
			)}
			{/*<input {...inputProps} ref={autosuggestInput} />*/}
			<input {...inputProps} />
			{/*	when I add in the above commented to code to show options when the caret is clicked, I get an error: https://github.com/moroshko/react-autosuggest/pull/631 */}
		</div>
	);

	const filterSDSFiles = async (
		selectedChemicalVal,
		selectedLoadVal
	) => {
		await getCustomerFiles(
			suggestionValue,
			selectedChemicalVal,
			selectedLoadVal
		)
			.then((r) => {
				const files = r.data.files;

				files.sort((a, b) => {
					let sortResult = 0;
					if (a.active < b.active) sortResult = 1;
					if (a.active > b.active) sortResult = -1;
					if (a.sheet_name > b.sheet_name) sortResult = 1;
					if (a.sheet_name < b.sheet_name) sortResult = -1;
					return sortResult;
				});

				const filenames = files.map((file) => ({
					sheet_file_name: file.sheet_file_name,
					sheet_name: file.sheet_name,
					chemical: file.chemical,
					load: file.load,
					brand: file.brand,
					updated: file.updated_at,
					active: file.active
				}));

				if (!selectedLoadVal && selectedChemicalVal) {
					const loads = [...new Set(files.map((file) => file.load))];
					// this is setting loads to empty and not allowing user to
					// select a load and upload, commenting out for now 12-10-20
					//setLoads(loads);
					if (loads.length === 1) {
						setSelectedLoad(loads[0]);
					}
				}
				filenames.sort((a, b) =>
					a.sheet_name > b.sheet_name ? 1 : -1
				);
				setProducts(filenames);
			})
			.catch((e) =>
				console.log('Error getting the customer files: ', e)
			);
	};

	const selectChemical = (e) => {
		setSelectedLoad('');
		const selectedChemicalVal = e.target.value;
		setSelectedChemical(selectedChemicalVal);
		if (selectedChemicalVal) {
			getChemicalLoads(selectedChemicalVal, null).then((r) => {
				setLoads(r.data.loads);
			});
		} else {
			setLoads([]);
		}
		filterSDSFiles(selectedChemicalVal, null).then();
	};

	const selectLoad = (e) => {
		const selectedLoadVal = e.target.value;
		setSelectedLoad(selectedLoadVal);
		//if (selectedLoadVal) {
		filterSDSFiles(selectedChemical, selectedLoadVal).then();
		//} else {
		// setLoads([]);
		//}
	};

	const deleteSDS = (e, ind) => {
		// e.preventDefault();
		const filename = e.target.dataset.filename;
		if (e.target.checked) {
			document.querySelector('#' + ind).classList.add('deleted');
			setCheckedFiles([...checkedFiles, filename]);
			// add it to the state array if not in there
			if (!filesToDelete.includes(filename)) {
				setFilesToDelete([...filesToDelete, filename]);
			}
			setTimeout(() => {
				window.scrollTo(0, document.body.scrollHeight);
			}, 0);
		} else {
			document.querySelector('#' + ind).classList.remove('deleted');
			// remove it from the state array
			const filesCopy = [...filesToDelete];
			const index = filesToDelete.indexOf(filename);
			if (index !== -1) {
				filesCopy.splice(index, 1);
				setFilesToDelete(filesCopy);
			}
		}
	};

	const deleteFilesClick = () => {
		confirmAlert({
			title: 'Confirm SDS Delete',
			message: `Are you sure you want to delete the selected SDS file${
				filesToDelete.length > 1 ? 's' : ''
			}?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteFilesAsync()
				},
				{
					label: 'No'
				}
			]
		});
	};

	const deleteFilesAsync = () => {
		// we can't delete the file if other companies are pointing to it, fix this
		deleteSDSFiles(filesToDelete, selectedCustomer)
			.then((r) => {
				if (r.status === 200) {
					alert.show(r.data.message, {
						timeout: 5000, // custom timeout just for this one alert
						type: 'success',
						position: positions.TOP_CENTER
					});

					const checked = document.querySelectorAll('input:checked');
					for (let i = 0; i < checked.length; i++) {
						checked[i].checked = false;
					}
					setFilesToDelete([]);
					// getFilesFromDB(selectedChemical, selectedLoad);
					filterSDSFiles(selectedChemical, selectedLoad).then();
				}
			})
			.catch((e) => console.log(e));
	};

	const checkMimeType = (event) => {
		//getting file object
		let files = event.target.files;
		//define message container
		let err = '';
		// list allow mime type
		const types = ['application/pdf'];
		// loop access array
		for (let x = 0; x < files.length; x++) {
			// compare file type find doesn't match
			if (types.every((type) => files[x].type !== type)) {
				// create error message and assign to container
				if (files.length === 1) {
					err = 'That is not a PDF file.';
				} else {
					err = 'At least one of those files is not a PDF file.';
				}
			}
		}

		if (err !== '') {
			// if message not same old that mean has error
			event.target.value = null; // discard selected file
			setError(err);
			return false;
		}
		return true;
	};

	const fileUploaded = (e) => {
		setError('');
		if (checkMimeType(e)) {
			const file = e.target.files;
			setUploadedFiles([...uploadedFiles, ...file]);
		}
	};

	const showUploadResults = () => {};

	const initUpload = async (e, newUploadedFiles = null) => {
		// first we need to check if there are any duplicates in the DB
		const filenames = [];
		const fullFilenames = [];
		if (newUploadedFiles === null) {
			newUploadedFiles = uploadedFiles;
		}

		let missingBrand = '';
		newUploadedFiles.forEach((f) => {
			let fName = f.name;
			fullFilenames.push(fName);
			if (
				!fName.includes('[') ||
				!fName.includes(']') ||
				(fName.includes('[') &&
					fName.includes(']') &&
					fName.indexOf(']') - fName.indexOf('[') === 1)
			) {
				missingBrand = fName;
			}
		});

		if (missingBrand !== '') {
			alert.show(`Missing brand for file: ${missingBrand}`, {
				timeout: 5000, // custom timeout just for this one alert
				type: 'error',
				position: positions.TOP_CENTER
			});
		} else {
			const updateDBData = {
				customer: selectedCustomer,
				chemical: selectedChemical,
				load: selectedLoad,
				files: newUploadedFiles,
				filenames: fullFilenames
			};
			updateAndUploadSDSFiles(updateDBData).then((r) => {
				console.log({ r });
				// filter files at the
				filterSDSFiles(selectedChemical, selectedLoad).then();
				clearUploadedFiles();
				// show notification to user of what just happened
				const results = r.updatedFiles;
				if (
					results.updatedFiles && // 👈 null and undefined check
					results.updatedFiles.length === 0
				) {
					if (results.addedFiles && results.addedFiles.length > 0) {
						alert.show(
							`Added ${results.addedFiles[0].sheetName} to ${selectedCustomer}!`,
							{
								timeout: 6000, // custom timeout just for this one alert
								type: 'success',
								position: positions.TOP_CENTER
							}
						);
					}
				} else if (
					results.updatedFiles && // 👈 null and undefined check
					results.updatedFiles.length > 0
				) {
					setUploadResultsData(results);
					setOpenUploadResults(true);
				}
			});
		}
		// const dupeResult = (await checkForSDSDuplicates(filenames)).data
		// 	.duplicateRes;
		// console.log({ dupeResult });
		// if (dupeResult.foundDupe) {
		// 	// show alert to the user to ask if they want to update all companies
		// 	let bodyText = 'Found <strong>';
		// 	if (dupeResult.numDupes === 1) {
		// 		bodyText += '1</strong> duplicate file';
		// 	} else {
		// 		bodyText += `${dupeResult.numDupes}</strong> duplicate files`;
		// 	}
		// 	bodyText += ' for the following compan';
		// 	if (dupeResult.companies.length === 1) {
		// 		bodyText += `y: <strong>${dupeResult.companies[0]}`;
		// 	} else {
		// 		bodyText += `ies: <strong>${dupeResult.companies.join(', ')}`;
		// 	}
		// 	bodyText += '</strong>.<br />Do you want to update th';
		// 	bodyText += `${
		// 		dupeResult.numDupes === 1 ? 'is file' : 'ese files'
		// 	} `;
		// 	bodyText += `for ${
		// 		dupeResult.companies.length === 1
		// 			? dupeResult.companies[0]
		// 			: 'all of those companies'
		// 	}`;
		// 	if (!dupeResult.companies.includes(selectedCustomer)) {
		// 		bodyText += ` and add ${
		// 			dupeResult.numDupes > 1 ? 'them' : 'it'
		// 		} to ${selectedCustomer}`;
		// 	}
		// 	bodyText += '?';
		// 	const titleText = `File${
		// 		dupeResult.numDupes > 1 ? 's' : ''
		// 	} already	exist${dupeResult.numDupes === 1 ? 's' : ''}!`;
		// 	confirmAlert({
		// 		customUI: ({ onClose }) => {
		// 			return (
		// 				<div className="custom-confirm-ui">
		// 					<h2 dangerouslySetInnerHTML={{ __html: titleText }} />
		// 					<p
		// 						className={'mb-5'}
		// 						dangerouslySetInnerHTML={{ __html: bodyText }}
		// 					/>
		// 					<div className={'d-flex justify-content-between'}>
		// 						<button
		// 							className={'btn btn-primary'}
		// 							onClick={() => {
		// 								// const fullFileNameInd = filenames.indexOf(dupe);
		// 								updateSDSForAllCompanies(
		// 									// dupeResult.shortName,
		// 									// dupeResult.longName,
		// 									dupeResult.companies,
		// 									filenames,
		// 									newUploadedFiles
		// 								);
		// 								onClose();
		// 							}}
		// 						>
		// 							Yes
		// 						</button>
		// 						<button
		// 							className={'btn btn-danger'}
		// 							onClick={() => {
		// 								onClose();
		// 								clearUploadedFiles();
		// 							}}
		// 						>
		// 							Cancel
		// 						</button>
		// 					</div>
		// 				</div>
		// 			);
		// 		}
		// 	});
		// } else {
		// 	// upload the rest of the files that are not duplicates
		// 	uploadFilesAsync(newUploadedFiles);
		// }
		// 	uploadFilesAsync();
		// }
	};

	// const updateSDSForAllCompanies = (
	// 	// SDSName,
	// 	// longSDSName,
	// 	companies,
	// 	filenames,
	// 	newUploadedFiles
	// ) => {
	// 	// const theFile = newUploadedFiles.find(
	// 	// 	(f) => f.name === longSDSName
	// 	// );
	//
	// 	// const data = new FormData();
	// 	// data.append('SDSName', SDSName);
	// 	// data.append('chemical', selectedChemical);
	// 	// data.append('load', selectedLoad);
	// 	// data.append('customer', selectedCustomer);
	// 	// data.append('companies', companies);
	// 	// data.append('update', 'true');
	// 	// data.append('onlyThisCompany', 'false');
	// 	// data.append('file', theFile);
	// 	// data.append('files', newUploadedFiles);
	// 	const updateDBData = {
	// 		// SDSName,
	// 		// companies,
	// 		customer: selectedCustomer,
	// 		chemical: selectedChemical,
	// 		load: selectedLoad,
	// 		files: newUploadedFiles,
	// 		filenames
	// 	};
	//
	// 	updateExistingSDS(updateDBData).then(async (res) => {
	// 		// console.log('update existing result: ', res);
	// 		if (res.status && res.status === 200) {
	// 			console.log({ res });
	// 			// check if we need to add as a new file to the selectedCustomer
	// 			// console.log({ companies });
	// 			if (!companies.includes(selectedCustomer)) {
	// 				// files do not exist for the selectedCustomer, add them
	// 				await addFilesToNewCompany(updateDBData);
	// 			}
	// 			// show success message to the user
	// 			// alert.show(`Updated ${longSDSName} for all companies!`, {
	// 			// 	timeout: 6000, // custom timeout just for this one alert
	// 			// 	type: 'success',
	// 			// 	position: positions.TOP_CENTER
	// 			// });
	// 			// remove the file from the array
	// 			// const newerUploadedFiles = newUploadedFiles.filter(
	// 			// 	(f) => f.name !== longSDSName
	// 			// );
	// 			// setUploadedFiles(newerUploadedFiles);
	// 			// if (newerUploadedFiles.length > 0) {
	// 			// 	// initialize the upload process again
	// 			// 	await initUpload(null, newerUploadedFiles);
	// 			// 	filterSDSFiles(selectedChemical, selectedLoad).then();
	// 			// } else {
	// 			// 	// refresh the file list at the bottom of the page
	// 			// 	filterSDSFiles(selectedChemical, selectedLoad).then();
	// 			// }
	// 		}
	// 	});
	// };

	// const updateSDSForThisCompany = (
	// 	SDSName,
	// 	longSDSName,
	// 	companies,
	// 	newUploadedFiles
	// ) => {
	// 	// check if there is only one company and it is the selected company
	// 	if (
	// 		companies.length > 1 ||
	// 		(companies.length === 1 && companies[0] !== selectedCustomer)
	// 	) {
	// 		if (!companies.includes(selectedCustomer)) {
	// 			// file does not exist for selected customer, add to DB and add to SDS folder
	// 			const data = new FormData();
	// 			data.append('customer', selectedCustomer);
	// 			data.append('SDSName', SDSName);
	// 			data.append('chemical', selectedChemical);
	// 			data.append('load', selectedLoad);
	// 			data.append('update', 'false');
	// 			data.append(
	// 				'file',
	// 				uploadedFiles.find((f) => f.name === longSDSName)
	// 			);
	// 			uploadFiles(data, setLoaded).then(async (res) => {
	// 				if (res.status && res.status === 200) {
	// 					alert.show('Uploaded 1 SDS file!', {
	// 						timeout: 5000, // custom timeout just for this one alert
	// 						type: 'success',
	// 						position: positions.TOP_CENTER
	// 					});
	// 					// remove the file from the array
	// 					const newerUploadedFiles = newUploadedFiles.filter(
	// 						(f) => f.name !== longSDSName
	// 					);
	// 					setUploadedFiles(newerUploadedFiles);
	// 					if (newerUploadedFiles.length > 0) {
	// 						// initialize the upload process again
	// 						await initUpload(null, newerUploadedFiles);
	// 					} else {
	// 						// refresh the file list at the bottom of the page
	// 						clearUploadedFiles();
	// 						filterSDSFiles(selectedChemical, selectedLoad).then();
	// 						document
	// 							.querySelector('.progress-bar')
	// 							.classList.remove('no-transition');
	// 						setTimeout(function () {
	// 							setLoaded(0);
	// 							if (document.querySelector('.progress-bar')) {
	// 								document
	// 									.querySelector('.progress-bar')
	// 									.classList.add('no-transition');
	// 							}
	// 						}, 5500); //run this after 3 seconds
	// 					}
	// 				}
	// 			});
	// 		} else {
	// 			// file exists for selectedCustomer, update it
	// 			const data = new FormData();
	// 			data.append('customer', selectedCustomer);
	// 			data.append('chemical', selectedChemical);
	// 			data.append('load', selectedLoad);
	// 			data.append('SDSName', SDSName);
	// 			data.append('update', 'false');
	// 			data.append('onlyThisCompany', 'true');
	//
	// 			data.append(
	// 				'file',
	// 				uploadedFiles.find((f) => f.name === longSDSName)
	// 			);
	// 			uploadFiles(data, setLoaded).then(async (res) => {
	// 				if (res.status && res.status === 200) {
	// 					alert.show(
	// 						`Updated ${SDSName} for ${selectedCustomer}!`,
	// 						{
	// 							timeout: 5000, // custom timeout just for this one alert
	// 							type: 'success',
	// 							position: positions.TOP_CENTER
	// 						}
	// 					);
	// 					// remove the file from the array
	// 					const newerUploadedFiles = newUploadedFiles.filter(
	// 						(f) => f.name !== longSDSName
	// 					);
	// 					setUploadedFiles(newerUploadedFiles);
	// 					if (newerUploadedFiles.length > 0) {
	// 						// initialize the upload process again
	// 						await initUpload(null, newerUploadedFiles);
	// 					} else {
	// 						// refresh the file list at the bottom of the page
	// 						clearUploadedFiles();
	// 						filterSDSFiles(selectedChemical, selectedLoad).then();
	// 						document
	// 							.querySelector('.progress-bar')
	// 							.classList.remove('no-transition');
	// 						setTimeout(function () {
	// 							setLoaded(0);
	// 							if (document.querySelector('.progress-bar')) {
	// 								document
	// 									.querySelector('.progress-bar')
	// 									.classList.add('no-transition');
	// 							}
	// 						}, 5500); //run this after 3 seconds
	// 					}
	// 				}
	// 			});
	// 			filterSDSFiles(selectedChemical, selectedLoad).then();
	// 		}
	// 	}
	// };

	const uploadFilesAsync = (newUploadedFiles = null) => {
		const data = new FormData();
		data.append('customer', selectedCustomer);
		data.append('SDSName', 'none');
		data.append('chemical', selectedChemical);
		data.append('load', selectedLoad);
		data.append('update', 'false');
		let missingBrand = false;
		let missingBrandFilename = '';
		const filenames = [];
		if (!newUploadedFiles) {
			newUploadedFiles = uploadedFiles;
		}
		newUploadedFiles.forEach((f) => {
			if (!missingBrand) {
				if (!f.name.includes('[') || !f.name.includes(']')) {
					missingBrand = true;
					missingBrandFilename = f.name;
				}
				filenames.push(f.name);
				data.append('file', f);
			}
		});
		if (!missingBrand) {
			data.append('filenames', filenames.toString());

			uploadFiles(data, setLoaded)
				.then((res) => {
					if (res.status && res.status === 200) {
						alert.show(
							`Uploaded ${uploadedFiles.length} SDS file${
								uploadedFiles.length > 1 ? 's' : ''
							}!`,
							{
								timeout: 5000, // custom timeout just for this one alert
								type: 'success',
								position: positions.TOP_CENTER
							}
						);
						clearUploadedFiles();
						filterSDSFiles(selectedChemical, selectedLoad).then();
						document
							.querySelector('.progress-bar')
							.classList.remove('no-transition');
						setTimeout(function () {
							setLoaded(0);
							if (document.querySelector('.progress-bar')) {
								document
									.querySelector('.progress-bar')
									.classList.add('no-transition');
							}
						}, 5500); //run this after 3 seconds
					} else if (res.Error) {
						// clearUploadedFiles();
						setLoaded(0);
						if (res.Error.error === 'duplicate sds') {
							// need to ask the user if they want to upload all
							confirmAlert({
								customUI: ({ onClose }) => {
									return (
										<div className="custom-confirm-ui">
											<h2>File already exists</h2>
											<p>
												The file{' '}
												<strong>{res.Error.sheetName}</strong> already
												exists for the following companies:{' '}
												<strong>{res.companies.customers}</strong>. Do
												you want to update it for all companies?
											</p>
											<div
												className={'d-flex justify-content-between'}
											>
												<button
													className={'btn btn-primary'}
													onClick={() => {
														onClose();
													}}
												>
													Yes
												</button>
												<button
													className={'btn btn-danger'}
													onClick={() => {
														onClose();
														clearUploadedFiles();
													}}
												>
													Cancel
												</button>
											</div>
										</div>
									);
								}
								// title: 'File Already Exists',
								// message: `This file already exists.  Do you want to update ${res.Error.sheetName} in all locations?`,
								// buttons: [
								// 	{
								// 		label: 'Yes'
								// 		// onClick: () => deleteUserAsync(id, name)
								// 	},
								// 	{
								// 		label: 'No'
								// 	}
								// ]
							});
							// setDuplicateMsg(
							// 	`Filename: ${res.Error.sheetName}, Customer: ${res.Error.customer}`
							// );
							// setDuplicateError(true);
							// alert.show(
							// 	'Duplicate file upload failed!  Please select a unique filename.',
							// 	{
							// 		timeout: 7000, // custom timeout just for this one alert
							// 		type: 'error',
							// 		position: positions.TOP_CENTER
							// 	}
							// );
						}
					}
				})
				.catch((e) => console.log('An error occurred: ', e));
		} else {
			alert.show(`Missing Brand for file: ${missingBrandFilename}`, {
				timeout: 8000, // custom timeout just for this one alert
				type: 'error',
				position: positions.TOP_CENTER
			});
			clearUploadedFiles();
		}
	};

	const clearUploadedFiles = (e) => {
		if (e) {
			e.preventDefault();
		}
		setUploadedFiles([]);
		setError('');
		setUniqueInputKey(Date.now());
		setDraggedOver(false);
	};

	const openPDF = (e, name) => {
		openPDFAsync(name, selectedCustomer);
	};

	const inputProps = {
		className: 'form-control',
		placeholder: 'Type in a customer',
		value: suggestionValue,
		onChange: onChangeSuggestion
	};

	return (
		<div className={'wrap'}>
			<div className={'container regulatory mb-2'}>
				{duplicateError && (
					<div
						className="alert alert-danger alert-dismissible fade show"
						role="alert"
					>
						<strong>Duplicate SDS!</strong> {duplicateMsg}
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="alert"
							aria-label="Close"
						/>
					</div>
				)}
				<h1 className={'text-center'}>Products</h1>
				<div className={'filters'}>
					<h4>Filter Products</h4>
					<div className={'row'}>
						<div className={'col'}>
							{customers.length > 0 ? (
								<>
									<h5>Customers Filter</h5>
									<Autosuggest
										suggestions={suggestions}
										onSuggestionsFetchRequested={
											onSuggestionsFetchRequested
										}
										onSuggestionsClearRequested={
											onSuggestionsClearRequested
										}
										getSuggestionValue={getSuggestionValue}
										onSuggestionSelected={customerSuggestionSelected}
										renderSuggestion={renderSuggestion}
										inputProps={inputProps}
										renderInputComponent={renderInputComponent}
										shouldRenderSuggestions={shouldRenderSuggestions}
									/>
								</>
							) : (
								<p>
									There are no customers available. Please add
									customers.
								</p>
							)}
						</div>
						<div className={'col'}>
							<h5>Type Filter</h5>
							{chemicals.length > 0 ? (
								<select
									className={'form-select'}
									onChange={selectChemical}
									value={selectedChemical}
								>
									<option value={''}>Select a type...</option>
									{chemicals.map((name, ind) => (
										<option key={ind}>{name}</option>
									))}
								</select>
							) : (
								<p>Please select a customer...</p>
							)}
						</div>
						<div className={'col'}>
							<h5>Loads Filter</h5>
							{loads.length > 0 ? (
								<select
									className={'form-select'}
									onChange={selectLoad}
									value={selectedLoad}
								>
									<option value={''}>Select a load...</option>
									{loads.map((name, ind) => (
										<option key={ind}>{name}</option>
									))}
								</select>
							) : (
								<p>Please select a type...</p>
							)}
						</div>
					</div>
				</div>
				<div className={'file-upload p-2 mb-2'}>
					<div
						className={`block-uploads${
							selectedLoad !== '' ? ' hidden' : ''
						}`}
					>
						<p>
							Please select a chemical and load before uploading
							files.
						</p>
					</div>
					<form method={'post'} action={'#'} id={'upload'}>
						<label>Upload Your SDS File(s)</label>
						<br />
						<div className={'form-group files color'}>
							<input
								type={'file'}
								key={uniqueInputKey}
								className={
									'form-control' +
									(draggedOver ? ' dragged-over' : '')
								}
								multiple
								onChange={(e) => fileUploaded(e)}
								onDragEnter={() => setDraggedOver(!draggedOver)}
								onDragLeave={() => setDraggedOver(!draggedOver)}
								onMouseLeave={() => setDraggedOver(false)}
							/>
							<div className={'row my-1'}>
								<div className={'col-6'}>
									{error !== '' && (
										<span className={'danger'}>{error}</span>
									)}
								</div>
								<div className={'col-6 text-right'}>
									<button
										className={'btn btn-sm btn-warning'}
										onClick={clearUploadedFiles}
									>
										Clear File Upload Queue
									</button>
								</div>
							</div>
							<div className="form-group">
								<Progress max="100" color="success" value={loaded}>
									{Math.round(loaded)}%
								</Progress>
							</div>
							<span
								className="d-inline-block"
								tabIndex="0"
								data-bs-toggle="tooltip"
								title="Please drag in some files or click Browse and add some files first"
							>
								<button
									type="button"
									className="btn btn-success btn-block btn-lg mt-2 upload"
									onClick={initUpload}
									disabled={
										selectedLoad === '' || uploadedFiles.length < 1
									}
								>
									Upload
								</button>
							</span>
						</div>
					</form>
					<br />
					<h4>
						{uploadedFiles.length +
							` ${
								uploadedFiles.length === 1 ? 'file' : 'files'
							} ready to upload`}
					</h4>
					<ul>
						{uploadedFiles.map((f, ind) => {
							return <li key={ind}>{f.name}</li>;
						})}
					</ul>
				</div>
				{products.length > 0 ? (
					<div className={'products-list'}>
						<div className={'m-1 fw-bold text-secondary'}>
							{products.length && (
								<span>{`${products.length} product${
									products.length > 1 ? 's' : ''
								} for ${selectedCustomer}${
									selectedChemical !== ''
										? ' - ' + selectedChemical
										: ''
								}${
									selectedLoad !== '' ? ' - ' + selectedLoad : ''
								}`}</span>
							)}
						</div>
						<ul
							className={`${products.length === 1 ? 'one-col' : ''}`}
						>
							{products.map((product, ind) => (
								<li
									key={product.sheet_name}
									id={'product-' + ind}
									// pdf={product.sheet_file_name}
									className={`sheet${
										product.active === false ? ' deleted' : ''
									}`}
								>
									<div
										className={'filename p-1'}
										onClick={(e) => openPDF(e, product.sheet_name)}
									>
										<p className={'m-0'}>{product.sheet_name}</p>
										<p className={'m-0'}>
											<span>Chemical: {product.chemical}</span>
											<span>Load: {product.load}</span>
											<span>Brand: {product.brand}</span>
										</p>
									</div>
									<div className={'d-flex justify-content-between'}>
										<div className={'updated p-1'}>
											Updated: {getFormattedDate(product.updated)}
										</div>
										{/*{product.active === true && (*/}
										<div className={'delete p-1'}>
											<div className={'pretty p-icon p-smooth'}>
												<input
													type={'checkbox'}
													data-filename={product.sheet_name}
													// checked={checkedFiles.includes(
													// 	product.sheet_name
													// )}
													onChange={(e) =>
														deleteSDS(e, 'product-' + ind)
													}
												/>
												<div className="state p-danger-o">
													<i className="icon fas fa-times" />
													<label>Delete</label>
												</div>
											</div>
										</div>
										{/*)}*/}
									</div>
								</li>
							))}
						</ul>
					</div>
				) : (
					selectedCustomer &&
					!gettingCustomers && (
						<div className={'m-1 fw-bold text-secondary'}>
							<span>
								{`0 products for ${selectedCustomer} - ${selectedChemical} - ${selectedLoad}`}
							</span>
						</div>
					)
				)}
				{filesToDelete.length > 0 && (
					<div className="d-flex justify-content-center mt-2">
						<button
							className={'btn btn-primary'}
							onClick={deleteFilesClick}
						>
							{`Delete Selected File${
								filesToDelete.length > 1 ? 's' : ''
							}`}
						</button>
					</div>
				)}
			</div>
			<UploadResults
				openUploadResults={openUploadResults}
				setOpenUploadResults={setOpenUploadResults}
				uploadResultsData={uploadResultsData}
			/>
		</div>
	);
};

export default Regulatory;
