import React, { useEffect, useState } from 'react';
import {
	faUserPlus,
	faUserMinus,
	faUserEdit,
	faArrowLeft,
	faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useAlert, positions } from 'react-alert';
import Sidebar from './Sidebar';
import {
	updateUser,
	deleteUser,
	getCustomerUsers
} from './API_Functions/UserFunctions';
import { confirmAlert } from 'react-confirm-alert';
import { capitalize } from '../utils/utils';
import {
	deleteCustomer,
	updateCustomer
} from './API_Functions/CustomerFunctions';
import {
	defaultFormState,
	getCustomersFromDB
} from '../utils/commonFunctions';
import {
	useGlobalCustomerActionsContext,
	useGlobalCustomerContext
} from '../context/customerContext';
import { useGlobalAuthContext } from '../context/authContext';
import {
	useGlobalModalContext,
	useGlobalModalActionsContext
} from '../context/modalContext';
import AddUser from './modals/AddUser';
import ReactTimeAgo from 'react-time-ago';

// modify here what types of users other user types can add,
// could add this to the DB, seems unnecessary right now
// const userTypeAddPermissions = {
// 	admin: ['admin', 'moderator'],
// 	moderator: ['customer-master', 'customer-admin', 'customer'],
// 	'customer-master': ['customer-admin', 'customer'],
// 	'customer-admin': ['customer']
// };

const Customers = () => {
	const [allCustomers, setAllCustomers] = useState([]);
	const [formState, setFormState] = useState(defaultFormState);
	const [editingAdmin, setEditingAdmin] = useState(false);
	const [originalMaster, setOriginalMaster] = useState({});
	const [editingCustomer, setEditingCustomer] = useState(false);
	const [editingCore, setEditingCore] = useState(false);
	const [originalCustomerName, setOriginalCustomerName] = useState(
		''
	);
	const [originalCore, setOriginalCore] = useState('');

	const customerData = useGlobalCustomerContext();
	const selectedCustomer = customerData.selectedCustomer;
	const setCustomerData = useGlobalCustomerActionsContext();
	const authData = useGlobalAuthContext();
	const userType = authData.user.type;
	const setModalData = useGlobalModalActionsContext();
	const modalOpen = useGlobalModalContext().open;

	const alert = useAlert();

	useEffect(() => {
		setCustomerData((prevState) => {
			return {
				...prevState,
				selectedCustomer: {
					id: '',
					name: '',
					master: [],
					users: []
				}
			};
		});
		getCustomersFromDB().then((r) => {
			setAllCustomers(r);
		});
		document.body.classList.add('overflow-hidden');
	}, []);

	useEffect(() => {
		if (selectedCustomer && selectedCustomer?.master) {
			setOriginalMaster({ ...selectedCustomer.master[0] });
		}
	}, [selectedCustomer]);

	useEffect(() => {
		if (editingCustomer) {
			setOriginalCustomerName(selectedCustomer.name);
		}
	}, [editingCustomer]);

	useEffect(() => {
		if (editingCore) {
			setOriginalCore(selectedCustomer.core);
		}
	}, [editingCore]);

	const addMasterAdmin = () => {
		setModalData((prevState) => {
			return {
				...prevState,
				addUserType: 'customer-master',
				open: true
			};
		});
	};

	const updateMasterAdmin = () => {
		const admin = {
			...selectedCustomer.master[0],
			firstName: selectedCustomer.master[0].first_name,
			lastName: selectedCustomer.master[0].last_name,
			userType: 'customer-master'
		};

		updateUser(admin)
			.then((r) => {
				if (r.data.error) {
					alert.show(r.data.error, {
						timeout: 5000, // custom timeout just for this one alert
						type: 'error',
						position: positions.TOP_CENTER,
						onOpen: () => {}, // callback that will be executed after this alert open
						onClose: () => {} // callback that will be executed after this alert is removed
					});
				} else {
					console.log(r);
					let message = `Updated ${admin.firstName} ${admin.lastName}`;
					if (r.data.emailSent) {
						message += ` and sent password reset email to ${admin.email}`;
					}
					message += '!';
					alert.show(message, {
						timeout: 5000, // custom timeout just for this one alert
						type: 'success',
						position: positions.TOP_CENTER,
						onOpen: () => {}, // callback that will be executed after this alert open
						onClose: () => {} // callback that will be executed after this alert is removed
					});
					setEditingAdmin(false);
				}
			})

			.catch((e) => console.log(e));
	};

	const addNewUserClick = () => {
		setFormState(defaultFormState);
		setModalData((prevState) => {
			return {
				...prevState,
				addUserType: 'user',
				open: true
			};
		});
	};

	const cancelEditingMasterAdmin = () => {
		setCustomerData({
			...customerData,
			selectedCustomer: {
				...selectedCustomer,
				master: [{ ...originalMaster }]
			}
		});
		setEditingAdmin(false);
	};

	const deleteAdmin = (id, first, last, custID) => {
		deleteUser(id).then((r) => {
			getCustomerUsers(custID).then((userRes) => {
				alert.show(`User ${first} ${last} deleted!`, {
					timeout: 5000, // custom timeout just for this one alert
					type: 'success',
					position: positions.TOP_CENTER
				});
				setCustomerData({
					...customerData,
					selectedCustomer: {
						...selectedCustomer,
						users: userRes.data.users.sort((a, b) => {
							return a.type > b.type;
						})
					}
				});
			});
		});
	};

	const deleteAdminClick = (user, custID) => {
		confirmAlert({
			title: 'Confirm User Delete',
			message: `Are you sure you want to delete ${user.first_name} ${user.last_name}?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () =>
						deleteAdmin(
							user.userID,
							user.first_name,
							user.last_name,
							custID
						)
				},
				{
					label: 'No'
				}
			]
		});
	};

	const editCustomer = (e) => {
		const name = e.currentTarget.name;
		const customer = {
			customer_id: selectedCustomer.id,
			customer_name: selectedCustomer.name,
			include_core_items: selectedCustomer.core ? 'true' : 'false'
		};

		updateCustomer(customer).then((r) => {
			if (r.status === 200) {
				getCustomersFromDB().then((r) => {
					setAllCustomers(r);
				});
				const msg =
					name === 'customer-name'
						? `Customer name changed to ${selectedCustomer.name}!`
						: `Set "Show Core Items" to ${
								selectedCustomer.core ? 'Yes' : 'No'
						  }`;
				alert.show(msg, {
					timeout: 5000, // custom timeout just for this one alert
					type: 'success',
					position: positions.TOP_CENTER
				});
				setEditingCustomer(false);
				setEditingCore(false);
			}
		});
	};

	const editUserClick = (user) => {
		setFormState({
			email: user.email,
			password: '',
			firstName: user.first_name,
			lastName: user.last_name,
			phone: user.phone,
			permissionType: '',
			userType: user.type,
			formType: 'edit',
			id: user.userID,
			customerName: ''
		});
		setModalData((prevState) => {
			return {
				...prevState,
				addUserType: 'general-admin',
				open: true
			};
		});
	};

	const deleteCustomerAsync = async () => {
		await deleteCustomer(selectedCustomer.name)
			.then((r) => {
				if (r.status === 200 && r.data.deleted === 1) {
					getCustomersFromDB().then((r) => {
						setAllCustomers(r);
					});
					setCustomerData((prevState) => {
						return {
							...prevState,
							selectedCustomer: {
								id: '',
								name: '',
								master: [],
								users: []
							}
						};
					});
					alert.show(`Customer ${selectedCustomer.name} deleted!`, {
						timeout: 5000, // custom timeout just for this one alert
						type: 'success',
						position: positions.TOP_CENTER
					});
				}
			})
			.catch((e) => console.log(e));
	};

	const deleteCustomerClick = () => {
		confirmAlert({
			title: 'Confirm Customer Delete',
			message: `Are you sure you want to delete ${selectedCustomer.name}?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteCustomerAsync()
				},
				{
					label: 'No'
				}
			]
		});
	};

	const handleCustomerChange = (e) => {
		const name = e.target.name;
		const value =
			name === 'includeCoreItems'
				? e.target.value === 'true'
				: e.target.value;

		const property = name === 'includeCoreItems' ? 'core' : 'name';

		setCustomerData({
			...customerData,
			selectedCustomer: {
				...selectedCustomer,
				[property]: value
			}
		});
	};

	const handleMasterInputChange = (e) => {
		const value = e.target.value;
		const master = { ...selectedCustomer.master[0] };
		const targetName = e.target.name;
		master[targetName] = value;

		setCustomerData({
			...customerData,
			selectedCustomer: {
				...selectedCustomer,
				master: [{ ...master }]
			}
		});
	};

	return (
		<div>
			<Sidebar
				allCustomers={allCustomers}
				setAllCustomers={setAllCustomers}
			/>
			<div className={'wrap customers-wrap'}>
				<div className="container customers">
					{!selectedCustomer?.name && !modalOpen && (
						<div className={'select-customer-msg'}>
							<FontAwesomeIcon
								className={'arrow-left'}
								icon={faArrowLeft}
								size={'1x'}
							/>
							<span>
								Please select a customer or add a new customer.
							</span>
						</div>
					)}

					<div className="row">
						<div className="col-md-9 mt-3 mx-auto">
							{selectedCustomer?.name && (
								<div className={'card selected-customer'}>
									<div className={'card-header'}>
										{editingCustomer ? (
											<h3>
												Selected Customer:{' '}
												<input
													className={
														'form-control edit-customer-input'
													}
													value={selectedCustomer.name}
													onChange={handleCustomerChange}
												/>
												<button
													onClick={editCustomer}
													name={'customer-name'}
													className={'fai'}
												>
													<FontAwesomeIcon
														className={'submit-customer-edit'}
														icon={faArrowCircleRight}
														size={'1x'}
													/>
												</button>
											</h3>
										) : (
											<h3>
												Selected Customer: {selectedCustomer.name}
											</h3>
										)}
										{editingCustomer ? (
											<button
												className={'btn edit-customer'}
												onClick={() => {
													setEditingCustomer(false);
													setCustomerData({
														...customerData,
														selectedCustomer: {
															...selectedCustomer,
															name: originalCustomerName
														}
													});
												}}
											>
												Cancel Editing
											</button>
										) : (
											<button
												className={'btn edit-customer'}
												onClick={() => setEditingCustomer(true)}
											>
												Edit Name
											</button>
										)}
										{/*<button*/}
										{/*	className={'btn btn-danger delete-customer'}*/}
										{/*	onClick={deleteCustomerClick}*/}
										{/*>*/}
										{/*	Delete*/}
										{/*</button>*/}
									</div>
									<div className={'card-body'}>
										<div className={'card p-1 mt-4'}>
											<div className={'form-group row'}>
												<label
													htmlFor={'includeCoreItems'}
													className={
														'col-sm-6 col-md-4 col-form-label col-form-label-lg'
													}
												>
													Include Core Items:
												</label>
												<div
													className={'col-sm-5 col-md-3 form-value'}
												>
													{editingCore ? (
														<>
															<select
																className={
																	'form-select edit-core-select'
																}
																name={'includeCoreItems'}
																id={'includeCoreItems'}
																value={selectedCustomer.core.toString()}
																onChange={handleCustomerChange}
															>
																<option value={'true'}>Yes</option>
																<option value={'false'}>No</option>
															</select>
															<button
																onClick={editCustomer}
																name={'customer-core'}
																className={'fai'}
															>
																<FontAwesomeIcon
																	className={'submit-core-edit'}
																	icon={faArrowCircleRight}
																	size={'1x'}
																/>
															</button>
														</>
													) : selectedCustomer.core ? (
														'Yes'
													) : (
														'No'
													)}
												</div>
											</div>
											{editingCore ? (
												<button
													className={'btn edit-core'}
													onClick={() => {
														setEditingCore(false);
														setCustomerData({
															...customerData,
															selectedCustomer: {
																...selectedCustomer,
																core: originalCore
															}
														});
													}}
												>
													Cancel Editing
												</button>
											) : (
												<button
													className={'btn edit-core'}
													onClick={() => setEditingCore(true)}
												>
													Edit
												</button>
											)}
										</div>

										{selectedCustomer.master === undefined ||
										selectedCustomer.master.length === 0 ||
										selectedCustomer.master[0] === undefined ? (
											<>
												<h4>
													There is no master admin for this account,
													please create one.
												</h4>
												<button
													className={'btn btn-primary'}
													onClick={() => addMasterAdmin()}
												>
													Add a Master Admin Account{' '}
													<FontAwesomeIcon
														className={'add--btn'}
														icon={faUserPlus}
														size={'1x'}
													/>
												</button>
											</>
										) : (
											<div className={'card p-1 mt-4'}>
												{(userType === 'admin' ||
													userType === 'moderator') && (
													<>
														{editingAdmin ? (
															<button
																className={
																	'btn btn-primary edit-master-admin cancel'
																}
																onClick={cancelEditingMasterAdmin}
															>
																Cancel
															</button>
														) : (
															<button
																className={
																	'btn btn-primary edit-master-admin'
																}
																onClick={() => setEditingAdmin(true)}
															>
																Edit
															</button>
														)}
														<h4 className={'mb-4'}>
															{selectedCustomer.name} Admin Master
															Account
														</h4>
														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminEmail'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																Email:
															</label>
															<div className={'col-sm-6 form-value'}>
																{editingAdmin ? (
																	<input
																		type={'text'}
																		className={'form-control'}
																		id={'masterAdminEmail'}
																		name={'email'}
																		value={
																			selectedCustomer.master[0].email
																		}
																		onChange={handleMasterInputChange}
																	/>
																) : (
																	selectedCustomer.master[0].email
																)}
															</div>
														</div>
														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminFirstName'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																First Name:
															</label>
															<div className={'col-sm-6 form-value'}>
																{editingAdmin ? (
																	<input
																		type={'text'}
																		className={'form-control'}
																		id={'masterAdminFirstName'}
																		name={'first_name'}
																		value={
																			selectedCustomer.master[0]
																				.first_name
																		}
																		onChange={handleMasterInputChange}
																	/>
																) : (
																	selectedCustomer.master[0]
																		.first_name
																)}
															</div>
														</div>
														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminLastName'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																Last Name:
															</label>
															<div className={'col-sm-6 form-value'}>
																{editingAdmin ? (
																	<input
																		type={'text'}
																		className={'form-control'}
																		id={'masterAdminLastName'}
																		name={'last_name'}
																		value={
																			selectedCustomer.master[0]
																				.last_name
																		}
																		onChange={handleMasterInputChange}
																	/>
																) : (
																	selectedCustomer.master[0].last_name
																)}
															</div>
														</div>

														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminPhone'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																Phone:
															</label>
															<div className={'col-sm-6 form-value'}>
																{editingAdmin ? (
																	<NumberFormat
																		format="(###) ###-####"
																		mask="_"
																		className={'form-control'}
																		id={'masterAdminPhone'}
																		name={'phone'}
																		value={
																			selectedCustomer.master[0].phone
																		}
																		onChange={handleMasterInputChange}
																	/>
																) : (
																	selectedCustomer.master[0].phone
																)}
															</div>
														</div>
														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminStatus'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																Status:
															</label>
															<div className={'col-sm-6 form-value'}>
																{selectedCustomer.master[0].status}
															</div>
														</div>
														<div className={'form-group row'}>
															<label
																htmlFor={'masterAdminStatus'}
																className={
																	'col-sm-3 col-form-label col-form-label-lg'
																}
															>
																Logged in data:
															</label>
															<div className={'col-sm-9 row'}>
																<div className={'col-sm-2'}>
																	<label
																		className={
																			'col-sm-8 col-form-label col-form-label-lg'
																		}
																	>
																		Count:
																	</label>
																	<label
																		className={
																			'col-sm-4 col-form-label col-form-label-lg'
																		}
																	>
																		{selectedCustomer.master[0]
																			.logged_in_count || 0}
																	</label>
																</div>
																<div className={'col-sm-8'}>
																	<label
																		className={
																			'col-sm-4 col-form-label col-form-label-lg'
																		}
																	>
																		Last logged in:
																	</label>
																	<label
																		className={
																			'col-sm-5 col-form-label col-form-label-lg'
																		}
																	>
																		{selectedCustomer.master[0]
																			.last_logged_in ? (
																			<ReactTimeAgo
																				date={
																					selectedCustomer.master[0]
																						.last_logged_in
																				}
																			/>
																		) : (
																			'never'
																		)}
																	</label>
																</div>
															</div>
														</div>
														{editingAdmin && (
															<button
																className={
																	'submit btn btn-primary w-25 mb-2 mx-auto'
																}
																onClick={updateMasterAdmin}
															>
																Submit
															</button>
														)}
													</>
												)}
											</div>
										)}
										<div className={'card p-1 mt-4'}>
											<h4 className={'mb-4'}>
												{selectedCustomer.name} General Admin and User
												Accounts
											</h4>
											<button
												className={'btn btn-primary add-admin'}
												onClick={() =>
													addNewUserClick(selectedCustomer.id)
												}
											>
												Add New User{' '}
												<FontAwesomeIcon
													className={'add--btn'}
													icon={faUserPlus}
													size={'1x'}
												/>
											</button>
											{selectedCustomer?.users?.length ? (
												selectedCustomer.users.map((user) => {
													console.log(
														'selectedCustomer users map: ',
														user
													);
													const userType = capitalize(
														user.type.substr(
															user.type.indexOf('-') + 1
														)
													);

													return (
														<div
															className={'customer-admin p-1'}
															key={user.email}
														>
															<p>Email: {user.email}</p>
															<p>
																Name:{' '}
																{user.first_name +
																	' ' +
																	user.last_name}
															</p>
															<p>Phone: {user.phone}</p>
															<p>
																Last logged in:{' '}
																{user.last_logged_in ? (
																	<ReactTimeAgo
																		date={user.last_logged_in}
																	/>
																) : (
																	'never'
																)}
															</p>
															<div
																className={`customer-user-type${
																	userType === 'User' ? ' user' : ''
																}`}
															>
																<span>{userType}</span>
															</div>
															<FontAwesomeIcon
																className={'edit-admin'}
																icon={faUserEdit}
																size={'1x'}
																onClick={() =>
																	editUserClick(
																		user,
																		selectedCustomer.id
																	)
																}
															/>
															<FontAwesomeIcon
																className={'delete-admin'}
																icon={faUserMinus}
																size={'1x'}
																onClick={() =>
																	deleteAdminClick(
																		user,
																		selectedCustomer.id
																	)
																}
															/>
														</div>
													);
												})
											) : (
												<h5>
													There are no general admin or user accounts
													for {selectedCustomer.name}.
												</h5>
											)}
										</div>
										{/*<div className={'card p-1 mt-4'}>*/}
										{/*	<h4>Chemicals and Loads</h4>*/}
										{/*	<div className={'row'}>*/}
										{/*		<div className={'col-6 row g-0'}>*/}
										{/*			<label*/}
										{/*				htmlFor={'chemical'}*/}
										{/*				className={'col-sm-4 col-form-label'}*/}
										{/*			>*/}
										{/*				Add a Chemical*/}
										{/*			</label>*/}
										{/*			<div className={'col-sm-6'}>*/}
										{/*				<input*/}
										{/*					type={'text'}*/}
										{/*					className={'form-control'}*/}
										{/*					id={'chemical'}*/}
										{/*					name={'chemical'}*/}
										{/*					value={''}*/}
										{/*					onChange={handleMasterInputChange}*/}
										{/*				/>*/}
										{/*			</div>*/}
										{/*			<div className={'col-sm-1'}>*/}
										{/*				<FontAwesomeIcon*/}
										{/*					className={'add-chemical ms-1'}*/}
										{/*					icon={faArrowCircleRight}*/}
										{/*					size={'2x'}*/}
										{/*					onClick={''}*/}
										{/*				/>*/}
										{/*			</div>*/}
										{/*		</div>*/}
										{/*		<div className={'col-6 row g-0'}>*/}
										{/*			<label*/}
										{/*				htmlFor={'chemical'}*/}
										{/*				className={'col-sm-4 col-form-label'}*/}
										{/*			>*/}
										{/*				Delete a Chemical*/}
										{/*			</label>*/}
										{/*			<div className={'col-sm-6'}>*/}
										{/*				<input*/}
										{/*					type={'text'}*/}
										{/*					className={'form-control'}*/}
										{/*					id={'chemical'}*/}
										{/*					name={'chemical'}*/}
										{/*					value={''}*/}
										{/*					onChange={handleMasterInputChange}*/}
										{/*				/>*/}
										{/*			</div>*/}
										{/*			<div className={'col-sm-1'}>*/}
										{/*				<FontAwesomeIcon*/}
										{/*					className={'add-chemical ms-1'}*/}
										{/*					icon={faArrowCircleRight}*/}
										{/*					size={'2x'}*/}
										{/*					onClick={''}*/}
										{/*				/>*/}
										{/*			</div>*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*</div>*/}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<AddUser
				setAllCustomers={setAllCustomers}
				formState={formState}
				setFormState={setFormState}
			/>
		</div>
	);
};

export default Customers;
