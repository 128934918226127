import React, { useEffect, useRef, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCustomersFromDB } from '../utils/commonFunctions';
import {
	useGlobalCustomerActionsContext,
	useGlobalCustomerContext
} from '../context/customerContext';
import {
	getCustomerAdmins,
	getCustomerMaster,
	getCustomerUsers
} from './API_Functions/UserFunctions';
import {
	useGlobalModalContext,
	useGlobalModalActionsContext
} from '../context/modalContext';

function Sidebar(props) {
	const { allCustomers, setAllCustomers } = props;
	const [filteredCustomers, setFilteredCustomers] = useState([]);

	const customerData = useGlobalCustomerContext();
	const selectedCustomer = customerData.selectedCustomer;
	const setCustomerData = useGlobalCustomerActionsContext();
	const setModalData = useGlobalModalActionsContext();

	console.log({ customerData });

	useEffect(() => {
		setFilteredCustomers(allCustomers);
	}, [allCustomers]);

	const addCustomer = async () => {
		setModalData((prevState) => {
			return {
				...prevState,
				addUserType: 'customer',
				open: true
			};
		});

		const allCustomersResult = await getCustomersFromDB();
		if (allCustomers) {
			setAllCustomers(allCustomersResult);
		}
	};

	const clearCustomer = () => {
		setCustomerData({
			...customerData,
			selectedCustomer: {
				id: '',
				name: '',
				core: '',
				master: [],
				users: []
			}
		});
		const listItems = document.querySelectorAll('.customer');
		listItems.forEach((li) => {
			li.classList.remove('selected');
		});
	};

	const filterList = (event) => {
		const updatedList = allCustomers.filter(function (item) {
			return (
				item.name
					.toLowerCase()
					.search(event.target.value.toLowerCase()) !== -1
			);
		});
		setFilteredCustomers(updatedList);
	};

	const setTheSelectedCustomer = (e, selectedCustomer) => {
		console.log({ selectedCustomer });
		const listItems = document.querySelectorAll('.customer');
		listItems.forEach((li) => {
			li.classList.remove('selected');
		});
		e.target.classList.add('selected');
		getCustomerMaster(selectedCustomer.name).then((masterRes) => {
			getCustomerUsers(selectedCustomer.id).then((userRes) => {
				setCustomerData({
					...customerData,
					selectedCustomer: {
						...selectedCustomer,
						master: masterRes.data.master || {},
						users:
							userRes.data.users.sort((a, b) => {
								return a.type > b.type;
							}) || []
					}
				});
			});
		});
	};

	return (
		<div className={'sidebar'}>
			{selectedCustomer?.name && (
				<div className={'selected-customer'}>
					Selected Customer:
					<br />
					{selectedCustomer.name}
					<FontAwesomeIcon
						className={'clear-customer'}
						icon={faWindowClose}
						size={'1x'}
						onClick={clearCustomer}
					/>
				</div>
			)}
			<div className={'position-relative'}>
				<input
					type={'text'}
					className={'form-control'}
					placeholder={'Search Customers...'}
					onChange={filterList}
				/>
				<FontAwesomeIcon
					className={'search-icon'}
					icon={faSearch}
					size={'1x'}
				/>
			</div>
			<button
				className={'btn btn-primary my-3 mx-auto d-block'}
				onClick={addCustomer}
			>
				Add a New Customer
			</button>
			<ul className={'list-group'}>
				{filteredCustomers.map(function (customer) {
					return (
						<li
							className={'list-group-item customer'}
							data-customer={customer.id}
							key={customer.id}
							onClick={(e) => setTheSelectedCustomer(e, customer)}
						>
							{customer.name}
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default Sidebar;
