import axios from 'axios';

let { baseURL } = require('./EnvVars');

export const addChemicals = (chemicals) => {
	return (
		axios
			// .post('/chemicals/addChemicals', { chemicals })
			.post(baseURL + '/chemicals/addChemicals', { chemicals })
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};

export const getChemicals = (customer, passUserID = false) => {
	console.log(`getting chemicals for ${customer}`);
	const user_id = passUserID ? localStorage.getItem('usertoken') : '';
	console.log({ user_id });
	return (
		axios
			// .get('/chemicals/getChemicals', {
			.get(baseURL + '/chemicals/getChemicals', {
				params: { customer, user_id }
			})
			.then((res) => {
				return res;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};

export const getChemicalLoads = (chemical) => {
	return (
		axios
			// .get('/chemicals/getChemicalLoads', {
			.get(baseURL + '/chemicals/getChemicalLoads', {
				params: { chemical }
			})
			.then((res) => {
				return res;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};

export const addLoads = (loads, chemical) => {
	return (
		axios
			// .post('/chemicals/addLoads', { loads, chemical })
			.post(baseURL + '/chemicals/addLoads', { loads, chemical })
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};
