import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import NumberFormat from 'react-number-format';
import CloseButton from './CloseButton';
import {
	addUserOfType,
	updateUser
} from '../API_Functions/UserFunctions';
import { createCustomer } from '../API_Functions/CustomerFunctions';
import { positions, useAlert } from 'react-alert';
import {
	defaultFormState,
	getCustomersFromDB
} from '../../utils/commonFunctions';
import { ModalStyles } from './ModalStyles';
import {
	useGlobalCustomerContext,
	useGlobalCustomerActionsContext
} from '../../context/customerContext';
import {
	useGlobalModalContext,
	useGlobalModalActionsContext
} from '../../context/modalContext';

ReactModal.setAppElement('#root');

const AddUser = (props) => {
	const {
		setAllCustomers,
		formState,
		setFormState,
		getCustomerUsers
	} = props;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [validationMsg, setValidationMsg] = useState(null);
	const [validEmail, setValidEmail] = useState(true);

	const customerData = useGlobalCustomerContext();
	const selectedCustomer = customerData.selectedCustomer;
	const setCustomerData = useGlobalCustomerActionsContext();
	const modalData = useGlobalModalContext();
	const addUserType = modalData.addUserType;
	const openAddUser = modalData.open;
	const setModalData = useGlobalModalActionsContext();

	const missingFields =
		addUserType === 'customer'
			? !formState.customerName ||
			  !formState.email ||
			  !formState.firstName ||
			  !formState.lastName ||
			  !formState.userType
			: formState.formType === 'new'
			? !formState.email ||
			  !formState.firstName ||
			  !formState.lastName ||
			  !formState.userType
			: !formState.email ||
			  !formState.firstName ||
			  !formState.lastName ||
			  !formState.userType;

	useEffect(() => {
		if (openAddUser === true) {
			setValidEmail(true);
			setIsSubmitting(false);
			setValidationMsg('');
			if (
				addUserType === 'customer-master' ||
				addUserType === 'customer'
			) {
				setFormState({ ...formState, userType: 'customer-master' });
			}
		}
		setIsSubmitting(false);
	}, [openAddUser, addUserType, setFormState]);

	const customer = selectedCustomer?.name || '';

	const fullName = `${formState.firstName} ${formState.lastName}`;

	const alert = useAlert();

	function emailIsValid(email) {
		return /\S+@\S+\.\S+/.test(email);
	}

	// function passwordIsValid(password) {
	// 	return password.length >= 8;
	// }

	useEffect(() => {
		if (isSubmitting && missingFields) {
			setValidationMsg(
				'Please fill in all the required fields below'
			);
		}

		if (formState.email && !emailIsValid(formState.email)) {
			setValidEmail(false);
		} else {
			setValidEmail(true);
		}
	}, [formState, isSubmitting, missingFields]);

	const handleChange = (e) => {
		const target = e.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		setFormState({
			...formState,
			[name]: value
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		if (missingFields) {
			setValidationMsg(
				'Please fill in all the required fields below'
			);
		} else if (formState.email && !emailIsValid(formState.email)) {
			setValidationMsg('Please fill in a valid email');
		} else {
			if (formState.formType === 'new') {
				handleSubmitAddUser(e).then();
			} else {
				handleSubmitEditUser(e);
			}
		}
	};

	const setOpenAddUser = (open) => {
		setModalData((prevState) => {
			return {
				...prevState,
				open
			};
		});
	};

	const handleSubmitAddUser = async (e) => {
		e.preventDefault();

		const customerName =
			addUserType === 'customer' ? formState.customerName : customer;
		const includeCore =
			addUserType === 'customer'
				? formState.includeCoreItems
					? 'true'
					: 'false'
				: '';
		const customerObj = {
			...formState,
			customerName,
			addUserType
		};

		console.log({ includeCore });
		if (addUserType === 'customer') {
			// add both customer and master admin
			const response = await createCustomer(
				customerName,
				includeCore
			).catch((e) => console.log(e));

			if (
				response &&
				response.status === 200 &&
				response.data.customerID
			) {
				const customerID = response.data.customerID;
				// create master admin with customer ID
				const customerWithID = { ...customerObj, customerID };
				addUserOfType(customerWithID).then((res) => {
					setOpenAddUser(false);
					getCustomersFromDB().then((r) => {
						setAllCustomers(r);
					});
					setCustomerData({
						...customerData,
						selectedCustomer: {
							...selectedCustomer,
							id: customerID,
							name: customerName,
							core: includeCore === 'true',
							master: [res.data.user],
							users: []
						}
					});

					alert.show(
						`${customerName} created and invite email sent to ${res.data.user.email}!`,
						{
							timeout: 5000, // custom timeout just for this one alert
							type: 'success',
							position: positions.TOP_CENTER
						}
					);
				});
			} else if (
				response.response.status === 409 &&
				response.response.data.error === 'unique violation'
			) {
				alert.show(
					`Customer ${customerName} already exists!  Please select a different customer name!`,
					{
						timeout: 8000, // custom timeout just for this one alert
						type: 'error',
						position: positions.TOP_CENTER
					}
				);
			}
		} else {
			const user = { ...formState, customerName };
			addUserOfType(user)
				.then((r) => {
					const resUser = r.data.user;
					setOpenAddUser(false);
					setFormState(defaultFormState);
					setCustomerData((prevState) => {
						if (addUserType === 'customer-master') {
							return {
								...prevState,
								selectedCustomer: {
									...selectedCustomer,
									name: customerName,
									master: [
										{
											userID: resUser.id,
											email: resUser.email,
											first_name: resUser.first_name,
											last_name: resUser.last_name,
											phone: resUser.phone,
											type: user.userType,
											id: ''
										}
									]
								}
							};
						} else {
							return {
								...prevState,
								selectedCustomer: {
									...selectedCustomer,
									name: customerName,
									users: [
										...prevState.selectedCustomer.users,
										{
											userID: resUser.id,
											email: resUser.email,
											first_name: resUser.first_name,
											last_name: resUser.last_name,
											phone: resUser.phone,
											type: user.userType,
											id: ''
										}
									]
								}
							};
						}
					});
					alert.show(
						`User ${resUser.first_name} ${resUser.last_name} created!`,
						{
							timeout: 5000, // custom timeout just for this one alert
							type: 'success',
							position: positions.TOP_CENTER
						}
					);
				})
				.catch((e) => console.log('Error adding User: ', e));
		}
	};

	const handleRadioChange = (e) => {
		const val = e.target.value;
		setFormState({ ...formState, userType: val });
	};

	const handleSubmitEditUser = (e) => {
		e.preventDefault();
		const user = {
			...formState
		};
		updateUser(user).then((r) => {
			if (r.status === 200 && r.data?.user) {
				setOpenAddUser(false);
				const user = r.data.user;
				setCustomerData((prevState) => {
					const newUser = {
						email: user.email,
						first_name: user.first_name,
						last_name: user.last_name,
						phone: user.phone,
						type: user.type,
						userID: user.id
					};

					return {
						...prevState,
						selectedCustomer: {
							...selectedCustomer,
							users: prevState.selectedCustomer.users
								.map((u) =>
									u.userID === newUser.userID ? newUser : u
								)
								.sort((a, b) => {
									return a.type > b.type;
								})
						}
					};
				});
				getCustomerUsers();

				alert.show(
					`User ${formState.firstName} ${formState.lastName} updated!`,
					{
						timeout: 5000, // custom timeout just for this one alert
						type: 'success',
						position: positions.TOP_CENTER
					}
				);
			}
		});
	};

	const handleAfterClose = () => {
		setFormState(defaultFormState);
	};

	return (
		<ReactModal
			isOpen={openAddUser}
			style={ModalStyles}
			className={'edit-details-modal'}
			closeTimeoutMS={1000}
			onAfterClose={handleAfterClose}
		>
			<CloseButton setOpenModal={setOpenAddUser} />
			<div>
				<h2 className={'title text-center'}>
					{formState.formType === 'new' ? (
						<>
							Add New{' '}
							{addUserType !== 'customer' && addUserType !== 'user'
								? `${
										addUserType === 'master-admin'
											? 'Master Admin'
											: 'User'
								  }	for ${customer}`
								: addUserType === 'customer'
								? 'Customer'
								: `User for ${customer}`}
						</>
					) : (
						`Edit User ${fullName}`
					)}
				</h2>
				<div className={'container'}>
					<form
						className={'modal-form'}
						onSubmit={onSubmit}
						noValidate
					>
						{validationMsg && (
							<h5 className={'alert alert-danger text-center'}>
								{validationMsg}
							</h5>
						)}
						{addUserType === 'customer' && (
							<>
								<div className={'form-group row mb-3'}>
									<label
										htmlFor={'customerName'}
										className={'col-sm-4 col-form-label'}
									>
										Customer Name:
									</label>
									<div className={'col-sm-8'}>
										<input
											type={'text'}
											className={`form-control${
												isSubmitting && !formState.customerName
													? ' input-error'
													: ''
											}`}
											id={'customerName'}
											name={'customerName'}
											onChange={handleChange}
											value={formState.customerName}
											maxLength={190}
										/>
									</div>
								</div>
								<h4 className={'border-bottom mb-4'}>
									Master Admin Account
								</h4>
							</>
						)}
						<div className={'form-group row mb-3'}>
							<label
								htmlFor={'email'}
								className={'col-sm-4 col-form-label'}
							>
								Email*
							</label>
							<div className={'col-sm-8'}>
								<input
									type={'email'}
									className={`form-control${
										(isSubmitting && !formState.email) || !validEmail
											? ' input-error'
											: ''
									}`}
									id={'email'}
									name={'email'}
									onChange={handleChange}
									value={formState.email}
									maxLength={150}
									required
								/>
							</div>
						</div>
						<div className={'form-group row mb-3'}>
							<label
								htmlFor={'firstName'}
								className={'col-sm-4 col-form-label'}
							>
								First Name*
							</label>
							<div className={'col-sm-8'}>
								<input
									type={'text'}
									className={`form-control${
										isSubmitting && !formState.firstName
											? ' input-error'
											: ''
									}`}
									id={'firstName'}
									name={'firstName'}
									onChange={handleChange}
									value={formState.firstName}
									maxLength={50}
									required
								/>
							</div>
						</div>
						<div className={'form-group row mb-3'}>
							<label
								htmlFor={'lastName'}
								className={'col-sm-4 col-form-label'}
							>
								Last Name*
							</label>
							<div className={'col-sm-8'}>
								<input
									type={'text'}
									className={`form-control${
										isSubmitting && !formState.lastName
											? ' input-error'
											: ''
									}`}
									id={'lastName'}
									name={'lastName'}
									onChange={handleChange}
									value={formState.lastName}
									maxLength={50}
									required
								/>
							</div>
						</div>
						<div className={'form-group row mb-3'}>
							<label
								htmlFor={'phone'}
								className={'col-sm-4 col-form-label'}
							>
								Phone
							</label>
							<div className={'col-sm-8'}>
								<NumberFormat
									format="(###) ###-####"
									mask="_"
									className={'form-control'}
									id={'phone'}
									name={'phone'}
									onChange={handleChange}
									value={formState.phone}
								/>
							</div>
						</div>
						{addUserType === 'user' ||
						formState.formType === 'edit' ? (
							<div className={'form-group row'}>
								<label className={'col-sm-4 col-form-label'}>
									User Type*
								</label>
								<div className={'col-sm-8'}>
									<div
										className={`shorter${
											isSubmitting && !formState.userType
												? ' input-error'
												: ''
										}`}
									>
										<div className={'form-check form-check-inline'}>
											<label
												htmlFor={'general-admin'}
												className={'radio'}
											>
												<input
													type={'radio'}
													name={'rdo'}
													id={'general-admin'}
													className={'hidden'}
													value={'customer-admin'}
													checked={
														formState.userType === 'customer-admin'
													}
													onChange={handleRadioChange}
												/>
												<span className={'label'} />
												General Admin
											</label>
										</div>
										<div className={'form-check form-check-inline'}>
											<label htmlFor={'user'} className={'radio'}>
												<input
													type={'radio'}
													name={'rdo'}
													id={'user'}
													className={'hidden'}
													value={'customer-user'}
													checked={
														formState.userType === 'customer-user'
													}
													onChange={handleRadioChange}
												/>
												<span className={'label'} />
												User
											</label>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className={'form-group'}>
								<label
									htmlFor={'includeCoreItems'}
									className={'row form-check-label'}
								>
									<div className={'col-sm-4'}>Include Core Items</div>
									<div className={'col-sm-8 form-check'}>
										<span className="checkbox__input">
											<input
												type={'checkbox'}
												className={'form-check-input'}
												id={'includeCoreItems'}
												name={'includeCoreItems'}
												onChange={handleChange}
												checked={formState.includeCoreItems}
											/>
											<span className={'checkbox__control'}>
												<svg
													xmlns={'http://www.w3.org/2000/svg'}
													viewBox={'0 0 24 24'}
													aria-hidden={'true'}
													focusable={'false'}
												>
													<path
														fill={'none'}
														stroke={'currentColor'}
														strokeWidth={'3'}
														d={'M1.73 12.91l6.37 6.37L22.79 4.59'}
													/>
												</svg>
											</span>
										</span>
									</div>
								</label>
							</div>
						)}
						<div className={'form-group row mb-2 mt-2'}>
							<div className={'col-sm-12 text-center'}>
								<button
									type={'submit'}
									className={'btn btn-secondary'}
									// onClick={
									// 	formState.formType === 'new'
									// 		? handleSubmitAddUser
									// 		: handleSubmitEditUser
									// }
								>
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</ReactModal>
	);
};

export default AddUser;
