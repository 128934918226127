import React from 'react';
import PropTypes from 'prop-types';

const currentYear = new Date().getFullYear();

function Footer(props) {
	return (
		<footer>
			{/*<div className={'footer-wrap d-none d-sm-block pb-1'}>*/}
			<div className={'footer-wrap pb-1'}>
				<div className={'mx-auto max-content'}>
					<div>EC Grow, PO BOX 837, Eau Claire, WI 54702</div>
					<div className={'text-start'}>
						Cust<span className={'d-none d-sm-inline'}>omer</span>{' '}
						Service: <a href={'tel:1-800-308-0322'}>800.308.0322</a> |{' '}
						<a href={'mailto:customerservice@ecgrow.com'}>
							customerservice@ecgrow.com
						</a>
					</div>
				</div>
				<hr />
				<div className={'text-small'}>
					All Safety Data Sheets are subject to change. &reg;{' '}
					{currentYear} EC Grow Inc.
				</div>
			</div>
		</footer>
	);
}

Footer.propTypes = {};

export default Footer;
