import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserPlus,
	faUserMinus,
	faUserEdit,
	faEye,
	faEyeSlash,
	faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { useAlert, positions } from 'react-alert';
import { Collapse } from 'reactstrap';
// import { Scrollbars } from 'react-custom-scrollbars';
import { Scrollbar } from 'react-scrollbars-custom';
import NumberFormat from 'react-number-format';
import DataTable from 'react-data-table-component';
import { isEmpty } from '../utils/utils';
import {
	addUser,
	deleteUserByEmail,
	getCustomerMaster,
	getCustomerUsers,
	updateUser
} from './API_Functions/UserFunctions';

import { useGlobalAuthContext } from '../context/authContext';
import Footer from './Footer';
import AddUser from './modals/AddUser';
import { ModalStyles } from './modals/ModalStyles';
import ReactModal from 'react-modal';
import {
	useGlobalModalActionsContext,
	useGlobalModalContext
} from '../context/modalContext';

ReactModal.setAppElement('#root');

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const initialInputState = {
	id: '',
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	phone: '',
	userType: '',
	newEmail: '',
	newFirstName: '',
	newLastName: '',
	newPhone: '',
	newUserType: ''
};

function CustomerAdmin(factory, deps) {
	const [inputState, setInputState] = React.useState(
		initialInputState
	);
	const [customerUsers, setCustomerUsers] = useState([]);
	const [master, setMaster] = useState({});
	const [otherUsers, setOtherUsers] = useState([]);
	const [isNewUserOpen, setIsNewUserOpen] = useState(false);
	const [editingUser, setEditingUser] = useState(false);
	const [editTitle, setEditTitle] = useState('');
	const [editingMaster, setEditingMaster] = useState(false);
	const [originalMaster, setOriginalMaster] = useState({});
	const [usersGridRows, setUsersGridRows] = useState([]);

	const authData = useGlobalAuthContext();
	const userType = authData.user.type.toLowerCase();
	const customer = authData.user.customer;
	const first = authData.user.first;
	const setModalData = useGlobalModalActionsContext();
	// const modalOpen = useGlobalModalContext().open;

	useEffect(() => {
		setOtherUsers(
			customerUsers.filter(
				(user) => user.type !== 'customer-master'
			) || []
		);
	}, [customerUsers]);

	const toggleNewUser = () => {
		setIsNewUserOpen(!isNewUserOpen);
	};

	const columns = useMemo(
		() => [
			{ name: 'Name', selector: 'name', sortable: true },
			{
				name: 'Email',
				selector: 'email',
				sortable: true,
				grow: 2
			},
			{ name: 'Phone', selector: 'phone', sortable: true },
			{ name: 'User Type', selector: 'type', sortable: true },
			{ name: 'Status', selector: 'status', sortable: true },
			{
				name: '',
				selector: 'actions',
				cell: (row) => (
					<div className={'d-flex justify-content-end w-100'}>
						<FontAwesomeIcon
							className={'edit-admin'}
							icon={faUserEdit}
							size={'1x'}
							onClick={() => editUserClick(row)}
						/>
						<FontAwesomeIcon
							className={'delete-admin'}
							icon={faUserMinus}
							size={'1x'}
							onClick={() => deleteUserClick(row.email, row.name)}
						/>
					</div>
				)
			}
		],
		deps
	);

	const justGetCustomerUsers = () => {
		getCustomerUsers().then((r) => {
			setCustomerUsers(r.data.users);
			setUsersGridRows(
				r.data.users.map((user, index) => {
					return {
						id: index,
						userID: user.userID,
						name: `${user.first_name} ${user.last_name}`,
						first: user.first_name,
						last: user.last_name,
						email: user.email,
						phone: user.phone,
						type: user.type,
						status: user.status
					};
				})
			);
		});
	};

	const getCustomerUsersAsync = () => {
		if (customer) {
			getCustomerMaster(customer).then((r) => {
				setMaster(r.data.master[0]);
				justGetCustomerUsers();
			});
		}
	};

	useEffect(() => {
		getCustomerUsersAsync();
	}, [customer]);

	const userTypeAddPermissions = [
		{ id: 'customer-admin', label: 'Admin' },
		{ id: 'customer-user', label: 'User' }
	];

	const alert = useAlert();

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputState({
			...inputState,
			[e.target.name]: value
		});
	};

	const handleRadioChange = (e) => {
		const val = e.target.value;
		setInputState({ ...inputState, newUserType: val });
	};

	const handleMasterInputChange = (e) => {
		const value = e.target.value;
		setMaster({
			...master,
			[e.target.name]: value
		});
	};

	const addNewUser = (e) => {
		e.preventDefault();
		if (userType === 'customer-admin') {
			inputState.userType = 'customer-user';
			inputState.newUserType = 'customer-user';
		}
		// should definitely add a proper form validation library to this such
		// as Formik - https://formik.org/
		if (
			isEmpty(inputState.newLastName) ||
			isEmpty(inputState.newFirstName) ||
			isEmpty(inputState.newEmail) ||
			isEmpty(inputState.newUserType)
		) {
			alert.show(`Please fill in all the inputs!`, {
				timeout: 5000, // custom timeout just for this one alert
				type: 'error',
				position: positions.TOP_CENTER
			});
		} else {
			// submit the form
			const user = {
				email: inputState.newEmail,
				first_name: inputState.newFirstName,
				last_name: inputState.newLastName,
				phone: inputState.newPhone,
				customerName: customer,
				userType: inputState.newUserType
			};
			addUser(user).then((r) => {
				if (r?.data?.user?.id) {
					// show message to the user that the site admin was created
					alert.show(
						`New User ${r.data.user.first_name} ${r.data.user.last_name} added and email invite sent out to ${r.data.user.email}!`,
						{
							timeout: 6000, // custom timeout just for this one alert
							type: 'success',
							position: positions.TOP_CENTER
						}
					);
					setInputState(initialInputState);
					getCustomerUsersAsync();
					// send out invite email
					// sendCustomerInviteEmail(user.email, user.first_name);
				}
			});
		}
	};

	const cancelEditing = (closeNewUserForm = true) => {
		setEditingUser(false);
		setInputState(initialInputState);
		if (closeNewUserForm) {
			setIsNewUserOpen(false);
		}
	};

	const deleteUserByEmailAsync = (email, name) => {
		deleteUserByEmail(email).then((r) => {
			if (r.status === 200) {
				alert.show(`User ${name} deleted!`, {
					timeout: 5000, // custom timeout just for this one alert
					type: 'success',
					position: positions.TOP_CENTER
				});
				getCustomerUsersAsync();
			} else {
				alert.show(`Error deleting user ${name}!`, {
					timeout: 5000, // custom timeout just for this one alert
					type: 'error',
					position: positions.TOP_CENTER
				});
			}
		});
	};

	const deleteUserClick = (email, name) => {
		cancelEditing();
		confirmAlert({
			title: 'Confirm Deactivate',
			message: `Are you sure you want to delete ${name}?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteUserByEmailAsync(email, name)
				},
				{
					label: 'No'
				}
			]
		});
	};

	const editUserClick = (user) => {
		setEditTitle(`Editing ${user.first_name} ${user.last_name}`);
		setInputState({
			email: user.email,
			firstName: user.first,
			lastName: user.last,
			phone: user.phone || '',
			userType: user.type,
			id: user.userID,
			formType: 'edit'
		});
		setOpenAddUser(true);
		// setEditingUser(true);
		// setIsNewUserOpen(true);
	};

	const setOpenAddUser = (open) => {
		setModalData((prevState) => {
			return {
				...prevState,
				addUserType: 'none',
				open
			};
		});
	};

	const editUserSubmit = (e) => {
		e.preventDefault();
		if (userType === 'customer-admin') {
			inputState.userType = 'customer-user';
		}
		if (
			isEmpty(inputState.lastName) ||
			isEmpty(inputState.firstName) ||
			isEmpty(inputState.email) ||
			isEmpty(inputState.userType)
		) {
			// should definitely add a proper form validation library to this such
			// as Formik - https://formik.org/
			alert.show(`Please fill in all the inputs!`, {
				timeout: 5000, // custom timeout just for this one alert
				type: 'error',
				position: positions.TOP_CENTER
			});
		} else {
			const user = {
				id: inputState.userID,
				firstName: inputState.firstName,
				lastName: inputState.lastName,
				email: inputState.email,
				phone: inputState.phone,
				userType: inputState.userType
			};

			updateUser(user).then((r) => {
				if (r.status === 200) {
					alert.show(
						`User ${inputState.userFirstName} ${inputState.lastName} updated!`,
						{
							timeout: 5000, // custom timeout just for this one alert
							type: 'success',
							position: positions.TOP_CENTER
						}
					);
					setInputState(initialInputState);
					justGetCustomerUsers();
				} else {
					alert.show(
						`Error updating user ${inputState.firstName} ${inputState.lastName}!`,
						{
							timeout: 5000, // custom timeout just for this one alert
							type: 'error',
							position: positions.TOP_CENTER
						}
					);
				}
			});
		}
	};

	const updateMasterAdmin = () => {
		const admin = {
			...master,
			firstName: master.first_name,
			lastName: master.last_name,
			userType: 'customer-master'
		};

		updateUser(admin)
			.then((r) => {
				if (r.data.error) {
					alert.show(r.data.error, {
						timeout: 5000, // custom timeout just for this one alert
						type: 'error',
						position: positions.TOP_CENTER,
						onOpen: () => {
							//console.log('hey');
						}, // callback that will be executed after this alert open
						onClose: () => {
							//console.log('closed');
						} // callback that will be executed after this alert is removed
					});
				} else {
					alert.show(
						`Updated ${admin.firstName} ${admin.lastName}!`,
						{
							timeout: 5000, // custom timeout just for this one alert
							type: 'success',
							position: positions.TOP_CENTER,
							onOpen: () => {
								//console.log('hey');
							}, // callback that will be executed after this alert open
							onClose: () => {
								//console.log('closed');
							} // callback that will be executed after this alert is removed
						}
					);
					setEditingMaster(false);
				}
			})

			.catch((e) => console.log(e));
	};

	return (
		<div className={'wrap background-img'}>
			<div className={'container admin'}>
				<div className={'white-right-bg'}> </div>
				<Scrollbar
					noDefaultStyles={false}
					style={{ height: `calc(100vh - 137px)` }}
					thumbYProps={{ className: 'thumbY' }}
				>
					<div className={'admin-wrapper pe-1'}>
						<div className={'row'}>
							<div className={'col-9 mx-auto mt-3'}>
								<h3 className={'fw-bold'}>Welcome {first}!</h3>
								<p className={'mb-3'}>What would you like to do?</p>
								{!editingUser && (
									<div className={'row'}>
										<div
											className={
												'col-lg-3 col-2 d-flex align-items-stretch'
											}
										>
											<button
												className={'btn add-user-btn'}
												type={'button'}
												onClick={toggleNewUser}
											>
												<FontAwesomeIcon
													icon={faUserPlus}
													size={'3x'}
												/>
												<div>
													Add a New User
													{/*<FontAwesomeIcon*/}
													{/*	className={`user-settings-down ${*/}
													{/*		isNewUserOpen*/}
													{/*			? 'fa-arrow-down open'*/}
													{/*			: 'fa-arrow-down'*/}
													{/*	}`}*/}
													{/*	id={'faIcon'}*/}
													{/*	icon={faAngleDown}*/}
													{/*	size={'1x'}*/}
													{/*/>*/}
												</div>
											</button>
										</div>
										<div className={'col-lg-9 col-10'}>
											{Object.keys(master).length > 0 && (
												<div className={'edit-master-admin p-1'}>
													<h3>Master Admin</h3>
													<div className={'row mb-2'}>
														<label
															htmlFor={'adminFirst'}
															className={
																'col-sm-1 col-form-label col-form-label'
															}
														>
															{editingMaster ? 'First:' : 'Name:'}
														</label>
														<div className={'col-sm-11 row'}>
															{editingMaster ? (
																<>
																	<div className={'col-sm-4'}>
																		<input
																			type={'text'}
																			className={
																				'form-control form-control'
																			}
																			id={'adminFirst'}
																			name={'first_name'}
																			value={master.first_name}
																			onChange={
																				handleMasterInputChange
																			}
																		/>
																	</div>
																	<label
																		htmlFor={'masterEmail'}
																		className={
																			'col-sm-1 col-form-label col-form-label'
																		}
																	>
																		Last:
																	</label>
																	<div className={'col-sm-4'}>
																		<input
																			type={'text'}
																			className={
																				'form-control form-control'
																			}
																			id={'adminLast'}
																			name={'last_name'}
																			value={master.last_name}
																			onChange={
																				handleMasterInputChange
																			}
																		/>
																	</div>
																	<div className={'col-sm-2'}>
																		<button
																			className={
																				'btn submit-edit-admin'
																			}
																			onClick={updateMasterAdmin}
																		>
																			Submit
																		</button>
																	</div>
																</>
															) : (
																<div className={'col-form-label'}>
																	{master.first_name}{' '}
																	{master.last_name}
																</div>
															)}
														</div>
													</div>
													<div className={'row'}>
														<label
															htmlFor={'masterEmail'}
															className={
																'col-sm-1 col-form-label col-form-label'
															}
														>
															Email:
														</label>
														<div className={'col-sm-11 row'}>
															<div className={'col-sm-5'}>
																{editingMaster ? (
																	<input
																		type={'text'}
																		className={
																			'form-control form-control'
																		}
																		id={'adminEmail'}
																		name={'email'}
																		value={master.email}
																		onChange={handleMasterInputChange}
																	/>
																) : (
																	<div className={'col-form-label'}>
																		{master.email}
																	</div>
																)}
															</div>
															{editingMaster && (
																<div className={'col-sm-3 offset-4'}>
																	<button
																		className={'btn btn-danger'}
																		onClick={() => {
																			if (!editingMaster) {
																				setOriginalMaster(master);
																			} else {
																				setMaster(originalMaster);
																			}
																			setEditingMaster(false);
																		}}
																	>
																		Cancel
																	</button>{' '}
																</div>
															)}
														</div>
													</div>
													{userType === 'master-admin' && (
														<FontAwesomeIcon
															className={'edit-master-icon'}
															icon={faUserEdit}
															size={'1x'}
															onClick={() => {
																if (!editingMaster) {
																	setOriginalMaster(master);
																} else {
																	setMaster(originalMaster);
																}
																setEditingMaster(!editingMaster);
															}}
														/>
													)}
												</div>
											)}
										</div>
									</div>
								)}
								<div className={'mt-2 addNewUser'} id={'addNewUser'}>
									<Collapse isOpen={isNewUserOpen}>
										<div className={'card'}>
											{!editingUser ? (
												<h4 className={'card-header'}>
													Add New User for <strong>{customer}</strong>
													<FontAwesomeIcon
														className={
															'user-settings-down fa-arrow-down open'
														}
														id={'faIcon'}
														icon={faAngleDown}
														size={'1x'}
														onClick={toggleNewUser}
													/>
												</h4>
											) : (
												<h3 className={'card-header'}>{editTitle}</h3>
											)}
											<div className={'card-body'}>
												<form className={'new-user'}>
													<div className={'form-group row mb-2'}>
														<label
															htmlFor={'userNewEmail'}
															className={'col-sm-3 col-form-label'}
														>
															Email:
														</label>
														<div className={'col-sm-8'}>
															<input
																type={'text'}
																className={'form-control'}
																id={'userNewEmail'}
																name={'newEmail'}
																value={inputState.newEmail}
																onChange={handleInputChange}
															/>
														</div>
													</div>

													<div className={'form-group row mb-2'}>
														<label
															htmlFor={'userNewFirstName'}
															className={'col-sm-3 col-form-label'}
														>
															First Name:
														</label>
														<div className={'col-sm-8'}>
															<input
																type={'text'}
																className={'form-control'}
																id={'userNewFirstName'}
																name={'newFirstName'}
																value={inputState.newFirstName}
																onChange={handleInputChange}
															/>
														</div>
													</div>
													<div className={'form-group row mb-2'}>
														<label
															htmlFor={'userNewLastName'}
															className={'col-sm-3 col-form-label'}
														>
															Last Name:
														</label>
														<div className={'col-sm-8'}>
															<input
																type={'text'}
																className={'form-control'}
																id={'userNewLastName'}
																name={'newLastName'}
																value={inputState.newLastName}
																onChange={handleInputChange}
															/>
														</div>
													</div>
													<div className={'form-group row mb-2'}>
														<label
															htmlFor={'userNewPhone'}
															className={'col-sm-3 col-form-label'}
														>
															Phone:{' '}
															<span
																className={'text-dimmed text-small'}
															>
																(optional)
															</span>
														</label>
														<div className={'col-sm-8'}>
															<NumberFormat
																format="(###) ###-####"
																mask="_"
																className={'form-control'}
																id={'userNewPhone'}
																name={'newPhone'}
																onChange={handleInputChange}
																value={inputState.newPhone}
															/>
														</div>
													</div>
													{userType !== 'customer-admin' && (
														<div className={'form-group row'}>
															<label
																className={'col-sm-3 col-form-label '}
															>
																User Type:
															</label>
															<div className={'col-sm-9'}>
																{userTypeAddPermissions.map(function (
																	type,
																	ind
																) {
																	return (
																		<div
																			className={
																				'form-check form-check-inline'
																			}
																			key={ind}
																		>
																			<label
																				htmlFor={type.id}
																				className={'radio'}
																			>
																				<input
																					type={'radio'}
																					name={'rdo'}
																					id={type.id}
																					className={'hidden'}
																					value={type.id}
																					checked={
																						inputState.newUserType ===
																						type.id
																					}
																					onChange={handleRadioChange}
																				/>
																				<span className={'label'} />
																				{type.label}
																			</label>
																		</div>
																	);
																})}
															</div>
														</div>
													)}
													<div
														className={
															'col-md-6 mx-auto d-flex justify-content-between'
														}
													>
														<div className={'mx-auto'}>
															{!editingUser ? (
																<input
																	type={'submit'}
																	className={
																		'btn add-new-user-btn d-block mx-auto'
																	}
																	value={'Submit'}
																	onClick={addNewUser}
																/>
															) : (
																<input
																	type={'submit'}
																	className={
																		'btn btn-primary d-block mx-auto'
																	}
																	value={'Submit'}
																	onClick={editUserSubmit}
																/>
															)}
														</div>
														<div className={'mx-auto'}>
															{!editingUser ? (
																<button
																	type={'button'}
																	className={
																		'btn btn-danger d-block mx-auto'
																	}
																	onClick={() =>
																		setInputState(initialInputState)
																	}
																>
																	Reset
																</button>
															) : (
																<button
																	type={'button'}
																	className={
																		'btn btn-danger d-block mx-auto'
																	}
																	onClick={cancelEditing}
																>
																	Cancel Editing
																</button>
															)}
														</div>
													</div>
												</form>
											</div>
										</div>
									</Collapse>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-9 mx-auto mt-3">
								<div className={'card'}>
									<div className={'card-header'}>
										<h3>
											Users List for <strong>{customer}</strong>
										</h3>
									</div>
									{Object.keys(master).length === 0 &&
										otherUsers.length === 0 && (
											<h5 className={'p-2 text-muted'}>
												No users here yet...
											</h5>
										)}
									<div className={'card-body'}>
										{otherUsers && (
											<div className={'users-list'}>
												{usersGridRows.length > 0 && (
													<DataTable
														noHeader={true}
														columns={columns}
														data={usersGridRows}
													/>
												)}
												{/*{otherUsers.map((user, idx) => {*/}
												{/*	const type =*/}
												{/*		user.type === 'customer-admin'*/}
												{/*			? 'admin'*/}
												{/*			: 'user';*/}
												{/*	const phone = user.phone || 'no phone';*/}
												{/*	return (*/}
												{/*		<li key={idx}>*/}
												{/*			{`${user.first_name} ${user.last_name} \u2014 ${user.email} \u2014 ${phone} \u2014`}{' '}*/}
												{/*			<span>{type}</span>*/}
												{/*			<FontAwesomeIcon*/}
												{/*				className={'edit-user'}*/}
												{/*				icon={faUserEdit}*/}
												{/*				size={'1x'}*/}
												{/*				onClick={() => editUserClick(user)}*/}
												{/*			/>*/}
												{/*			<FontAwesomeIcon*/}
												{/*				className={'delete-user'}*/}
												{/*				icon={faUserMinus}*/}
												{/*				size={'1x'}*/}
												{/*				onClick={() =>*/}
												{/*					deleteUserClick(*/}
												{/*						user.email,*/}
												{/*						user.first_name,*/}
												{/*						user.last_name*/}
												{/*					)*/}
												{/*				}*/}
												{/*			/>*/}
												{/*		</li>*/}
												{/*	);*/}
												{/*})}*/}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Scrollbar>
			</div>
			<Footer />
			<AddUser
				userType={userType}
				customStyles={ModalStyles}
				formState={inputState}
				setFormState={setInputState}
				getCustomerUsers={justGetCustomerUsers}
			/>
		</div>
	);
}

export default CustomerAdmin;
