import React from 'react';
import PropTypes from 'prop-types';
import SdsProductLink from './SDSProductLink';

const logos = require.context('../img/logos', true);
const awardChemicalBrands = [
	'acelepryn',
	'barricade',
	'cad',
	'dimension',
	'ice-melt',
	'merit',
	'prodiamine',
	'straight',
	'trimec'
];
const genericAwardChemicals = [
	'bifenthrin',
	'imi-lambda',
	'mallet',
	'q3',
	'viper'
];

const SDSDataTable = (props) => {
	const { data, customer } = props;

	return (
		<div className={'sds-data-table'}>
			{data.map((p) => {
				let iconName = logos('./pdf-icon.png');
				if (p.brand.toLowerCase() === 'prolinks') {
					iconName = logos('./prolinks.png');
				} else if (p.brand.toLowerCase() === 'award') {
					const type = p.chemical.replace(/\s+/g, '-').toLowerCase();

					if (awardChemicalBrands.includes(type)) {
						iconName = logos(`./${type + '-award.png'}`);
					} else {
						iconName = logos('./award.png');
					}
				} else {
					iconName = logos('./private-label.png');
				}
				return (
					<SdsProductLink
						icon={iconName.default}
						product={p}
						customer={customer}
					/>
				);
			})}
		</div>
	);
};

SDSDataTable.propTypes = {
	data: PropTypes.array.isRequired
};

export default SDSDataTable;
