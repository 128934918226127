import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useHistory
} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Customers from './components/Customers';

import ResetPasswordForm from './components/ResetPasswordForm';
import SDSFiles from './components/SDSFiles';
import Regulatory from './components/Regulatory';
import CustomerAdmin from './components/CustomerAdmin';
import SiteAdmin from './components/SiteAdmin';
import axios from 'axios';
import {
	useGlobalAuthActionsContext,
	useGlobalAuthContext
} from './context/authContext';
import AuthRoute from './components/AuthRoute';
import { AuthRoutes, NonAuthRoutes } from './config/AuthRoutesLogic';
import { userRoles } from './config/typedRoles';
import Home from './components/Home';
import Error404 from './components/Error-404';

const { baseURL } = require('./components/API_Functions/EnvVars');

const App = () => {
	const [error, setError] = useState(null);
	const setAuthData = useGlobalAuthActionsContext();
	const loading = useGlobalAuthContext().loading;
	let history = useHistory();

	const checkForLoggedInUser = () => {
		try {
			axios
				.get(baseURL + '/users/user')
				.then((res) => {
					if (res.data.user) {
						const user = res.data.user;
						if (window.location.pathname === '/') {
							switch (user.type) {
								case 'admin':
									history.push('/site-admin');
									break;
								case 'customer-admin':
									history.push('/customer-admin');
									break;
								case 'customer-user':
									history.push('/sds-files');
									break;
								default:
									history.push('/sds-files');
							}
						}

						setAuthData((prevState) => {
							return {
								...prevState,
								loading: false,
								user: {
									email: user.email,
									type: user.type,
									customer: user.customer,
									first: user.first
								}
							};
						});
					} else {
						// we have no valid logged in user
						// console.log('no logged in user!');
						setAuthData((prevState) => {
							return {
								...prevState,
								loading: false,
								user: {
									email: '',
									type: '',
									customer: '',
									first: ''
								}
							};
						});
					}
				})
				.catch((e) => {
					if (e.response.status === 502) {
						setAuthData((prevState) => {
							return {
								...prevState,
								loading: false,
								user: {
									email: '',
									type: '',
									customer: '',
									first: ''
								}
							};
						});
						setError('Error connecting to backend server!');
					}
				});
		} catch (e) {
			console.log('axios error 2: ', e);
		}
	};

	useEffect(() => {
		checkForLoggedInUser();
	}, []);

	const ProtectedRoutesContainer = () => (
		<>
			<Navbar />
			<Switch>
				<AuthRoute
					exact
					path={AuthRoutes.siteAdmin}
					Component={SiteAdmin}
					requiredRoles={[...userRoles.admins]}
				/>
				<AuthRoute
					exact
					path={AuthRoutes.customer}
					Component={Customers}
					requiredRoles={[...userRoles.admins]}
				/>
				<AuthRoute
					exact
					path={AuthRoutes.customerAdmin}
					Component={CustomerAdmin}
					requiredRoles={[...userRoles.customerAdmins]}
				/>
				<AuthRoute
					exact
					path={AuthRoutes.regulatory}
					Component={Regulatory}
					requiredRoles={[...userRoles.admins]}
				/>
				<AuthRoute
					exact
					path={AuthRoutes.SDSFiles}
					Component={SDSFiles}
					requiredRoles={[...userRoles.all]}
				/>
				<Route component={Error404} />
			</Switch>
		</>
	);

	return (
		<>
			{/*<ReactNotification />*/}
			{!loading && (
				<div className="App">
					{error && (
						<h3
							className={
								'alert alert-danger text-center position-absolute w-100'
							}
						>
							{error}
						</h3>
					)}
					<Switch>
						<Route
							exact
							path={NonAuthRoutes.root}
							render={(props) => <Home {...props} form={'login'} />}
						/>
						<Route
							exact
							path={NonAuthRoutes.forgotPassword}
							render={(props) => (
								<Home {...props} form={'forgotPassword'} />
							)}
						/>
						<Route
							path={NonAuthRoutes.resetPassword}
							render={(props) => (
								<Home {...props} form={'resetPassword'} />
							)}
						/>
						<Route
							path={NonAuthRoutes.createPassword}
							render={(props) => (
								<Home {...props} form={'createPassword'} />
							)}
						/>
						<Route component={ProtectedRoutesContainer} />
						{/*<Route component={Error404} />*/}
					</Switch>
				</div>
			)}
		</>
	);
};

export default App;
