import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Scrollbars } from 'rc-scrollbars';
import CloseButton from './CloseButton';
import { ModalStyles as customStyles } from './ModalStyles';
import {
	useGlobalModalContext,
	useGlobalModalActionsContext
} from '../../context/modalContext';

const wideCustomStyles = {
	content: { ...customStyles.content, width: '650px' },
	overlay: { ...customStyles.overlay }
};

ReactModal.setAppElement('#root');

const UploadResults = (props) => {
	console.log({ props });
	const {
		openUploadResults,
		setOpenUploadResults,
		uploadResultsData
	} = props;

	return (
		<ReactModal
			isOpen={openUploadResults}
			style={wideCustomStyles}
			className={'upload-results-modal'}
			closeTimeoutMS={1000}
		>
			<CloseButton setOpenModal={setOpenUploadResults} />
			<div>
				<h2 className={'title text-center'}>Upload Results</h2>
				<Scrollbars
					autoHeight
					autoHeightMin={200}
					autoHeightMax={600}
				>
					<div className={'p-3'}>
						{uploadResultsData.addedFiles && (
							<div>
								<strong>Added:</strong>
								<ul className={'added-list'}>
									{uploadResultsData.addedFiles.map((file) => {
										return (
											<li className={'p-1'}>
												File:{' '}
												<span className={'file'}>
													{file.sheetName}
												</span>
												<div className={'ms-3'}>
													to{' '}
													<span className={'customer'}>
														{file.customer}
													</span>
												</div>
											</li>
										);
									})}
								</ul>
								<hr />
							</div>
						)}
						{uploadResultsData.updatedFiles && (
							<div>
								<strong>Updated:</strong>
								<br />
								<ul className={'updated-list'}>
									{uploadResultsData.updatedFiles.map((file) => {
										return (
											<li>
												File:{' '}
												<span className={'file'}>
													{file.sheetName}
												</span>
												<div>For the following customers:</div>
												{file.companies.length && (
													<ul>
														{file.companies.map((c) => (
															<li className={'customer ms-3'}>{c}</li>
														))}
													</ul>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						)}
					</div>
				</Scrollbars>
			</div>
		</ReactModal>
	);
};

export default UploadResults;
