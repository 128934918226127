import axios from 'axios';
import { detect } from 'detect-browser';

let { baseURL } = require('./EnvVars');

export const registerCustomer = (newUser) => {
	return axios
		.post('users/registerCustomer', {
			customer_name: newUser.customer_name,
			first_name: newUser.first_name,
			last_name: newUser.last_name,
			email: newUser.email,
			password: newUser.password,
			categories: newUser.categories
		})
		.then((response) => {
			return response;
		})
		.catch((e) => {
			let error =
				'Error: ' + e.response.status + ' - ' + e.response.data.error;
			if (e.response.status === 403) {
				error = "You don't have access to do that.";
			}

			return Promise.reject(error);
		});
};

export const login = (user) => {
	return (
		axios
			// .post('/users/login', {
			.post(baseURL + '/users/login', {
				//.post(baseURL + '/auth/', {
				username: user.email,
				password: user.password,
				rememberMe: user.rememberMe
			})
			.then((res) => {
				// localStorage.setItem('usertoken', res.data.user.id);
				// if (res.data.user.email) {
				// 	localStorage.setItem('useremail', res.data.user.email);
				// }
				// if (res.data.user.type) {
				// 	localStorage.setItem('usertype', res.data.user.type);
				// }
				// if (res.data.user.customer_name) {
				// 	localStorage.setItem(
				// 		'customername',
				// 		res.data.user.customer_name
				// 	);
				// }
				return res.data;
			})
			.catch((err) => {
				return 'Invalid username or password';
			})
	);
};

export const logout = () => {
	return (
		axios
			// .post(baseURL + '/users/logout')
			.post(baseURL + '/logout')
			.then((res) => {
				return res;
			})
			.catch((err) => {
				console.log('UserFunction logout err: ', err);
			})
	);
};

export const forgotPassword = (email) => {
	const browser = detect();
	return axios
		.put(baseURL + '/users/forgot-password', {
			email,
			browser
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			if (err.response) {
				return { status: err.response.status };
			}
			return err;
		});
};

export const resetPassword = (token, newPassword) => {
	try {
		return axios
			.put(baseURL + '/users/reset-password', {
				token,
				password: newPassword
			})
			.then((res) => {
				return res;
			})
			.catch((err) => {
				if (err.response) {
					return err.response;
				}
				return err;
			});
	} catch (e) {
		return Promise.reject(e);
	}
};

export const addUser = (user) => {
	return axios
		.post(baseURL + '/users/addUser', {
			...user
		})
		.then((response) => {
			return response;
		})
		.catch((e) => {
			console.log(e);
			let error =
				'Error: ' + e.response.status + ' - ' + e.response.data.error;
			if (e.response.status === 403) {
				error = "You don't have access to do that.";
			}
			return error;
		});
};

export const updateUser = (user) => {
	return axios
		.post(baseURL + '/users/updateUser', {
			user
		})
		.then((response) => {
			return response;
		})
		.catch((e) => {
			let error = '';
			if (e.response) {
				error =
					'Error: ' +
					e.response.status +
					' - ' +
					e.response.data.error;
				if (e.response.status === 403) {
					error = "You don't have access to do that.";
				}
			} else {
				error = 'Error creating user!';
			}

			return error;
		});
};

export const getUser = (id) => {
	if (id) {
		return (
			axios
				// .get(`/users/getuser/${id}`)
				.get(baseURL + `/users/getuser/${id}`)
				.then((res) => {
					return res;
				})
				.catch((err) => {
					return err;
				})
		);
	} else {
		return null;
	}
};

export const getInternalUsers = () => {
	return axios
		.get(baseURL + '/users/getInternalUsers')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export const getCustomerUsers = (customer_id = null) => {
	return (
		axios
			// .get('/users/getCustomerUsers', {
			.get(baseURL + '/users/getCustomerUsers', {
				params: { customer_id }
			})
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return err;
			})
	);
};

export const customers = () => {
	return (
		axios
			// .get('/users/customers')
			.get(baseURL + '/users/customers')
			.then((res) => {
				return _wait(500, res);
			})
			.catch((err) => {
				return err;
			})
	);
};

// TODO: make all these variables an object
export const editCustomer = (
	id,
	customer_name,
	email,
	first_name,
	last_name,
	categories
) => {
	return axios
		.put('/users/editCustomer', {
			id,
			customer_name,
			email,
			first_name,
			last_name,
			categories
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export const deleteUser = (id) => {
	return axios
		.delete(baseURL + '/users/deleteUser', { data: { id } })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export const deleteUserByEmail = (email) => {
	return axios
		.delete(baseURL + '/users/deleteUserByEmail', { data: { email } })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export const getCategories = (customer) => {
	return axios
		.get('/users/getCategories', {
			params: { customer }
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export const getCustomerFiles = (
	customer = null,
	chemical = null,
	load = null,
	includeCore = false
) => {
	let params = load
		? { customer, chemical, load }
		: chemical
		? { customer, chemical }
		: { customer };
	params = { ...params, includeCore };
	return (
		axios
			// .get('/SDS/getCustomerFiles', { params })
			.get(baseURL + '/SDS/getCustomerFiles', { params })
			.then((res) => {
				return res;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};

export const getCustomerFromUser = () => {
	return (
		axios
			// .get('/SDS/getCustomerFiles', { params })
			.get(baseURL + '/users/getCustomerFromUser')
			.then((res) => {
				return res;
			})
			.catch((e) => {
				return Promise.reject(e);
			})
	);
};

export const getPDFBlob = (pdf) => {
	try {
		return axios
			.get(baseURL + '/PDF/openPDFFile', {
				params: { pdf },
				responseType: 'blob',
				headers: {
					Accept: 'application/pdf'
				}
			})
			.then((res) => {
				if (res.status === 200) {
					// console.log('res.data: ', res.data);
					// const file = new Blob([res.data], {
					// 	type: 'application/pdf'
					// });
					const blob = res.data;
					// convert blob to base64
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					return new Promise(function (resolve, reject) {
						reader.onloadend = async function () {
							const base64String = await reader.result;
							resolve(base64String);
						};
					});
				} else {
					return res;
				}
			});
	} catch (e) {
		console.log(e);
	}
};

export const downloadPDF = (e, pdf, customer) => {
	// prevent the click from bubbling up and opening the pdf in a new tab
	e.stopPropagation();

	// go get the file and send it as a download to the browser
	try {
		return axios
			.get(baseURL + '/PDF/downloadPDFFile', {
				params: { pdf, customer },
				responseType: 'arraybuffer',
				headers: {
					Accept: 'application/pdf'
				}
			})
			.then((res) => {
				const url = window.URL.createObjectURL(
					new Blob([res.data], { type: 'application/pdf' })
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', pdf + '.pdf');
				document.body.appendChild(link);
				link.click();
			});
	} catch (e) {
		console.log(e);
	}
};

export const openPDFAsync = (pdf, customer) => {
	const windowReference = window.open();

	try {
		axios
			.get(baseURL + '/PDF/openPDFFile', {
				params: { pdf, customer },
				responseType: 'blob'
				// headers: {
				// 	Accept: 'application/pdf'
				// }
			})
			.then((res) => {
				if (res.status === 200) {
					const file = new Blob([res.data], {
						type: 'application/pdf'
					});
					// this line will show the open/save prompt and clicking open will open
					// the pdf with a url like:
					// file:///C:/Users/mdodge/AppData/Local/Temp/13-0-6%20.125%20Dimension%20with%20Nutrismart%20570-4020[Award,SDS].pdf

					// option 1
					//saveFile(file, pdf);

					//Build a URL from the file, this code will automatically open up the
					// pdf with a URL like:
					// blob:http://localhost:3000/45b28e60-f9f7-460a-99e2-740e92d393d5
					const fileURL = URL.createObjectURL(file);

					// option 2
					// window.open(fileURL, '_blank');
					windowReference.location = fileURL;

					// option 3
					// const a = document.createElement('a');
					// a.href = fileURL;
					// a.target = '_blank';
					// a.click();

					// option 4
					// const byteCharacters = atob(res.data);
					// const byteNumbers = new Array(byteCharacters.length);
					// for (let i = 0; i < byteCharacters.length; i++) {
					// 	byteNumbers[i] = byteCharacters.charCodeAt(i);
					// }
					// const byteArray = new Uint8Array(byteNumbers);
					// const file2 = new Blob([byteArray], {
					// 	type: 'application/pdf;base64'
					// });
					// const fileURL2 = URL.createObjectURL(file2);
					// window.open(fileURL2);

					//option 5
					// let pdfWindow = window.open('');
					// pdfWindow.document.write(
					// 	"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
					// 		encodeURI(res.data) +
					// 		"'></iframe>"
					// );
				} else {
					return res;
				}
			});
	} catch (e) {
		console.log(e);
	}
};

export const getCustomerAdmins = (customer) => {
	if (customer) {
		return (
			axios
				// .get('/users/getCustomerAdmins', {
				.get(baseURL + '/users/getCustomerAdmins', {
					params: { customer }
				})
				.then((res) => {
					return res;
				})
				.catch((err) => {
					return err;
				})
		);
	} else {
		return {};
	}
};

export const addUserOfType = async (user) => {
	return await axios
		.post(baseURL + '/users/addUserOfType', {
			params: { user }
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log({ err });
			return err;
		});
};

export const getCustomerMaster = async (customer) => {
	if (customer) {
		return await axios
			// .get('/users/getCustomerMaster', {
			.get(baseURL + '/users/getCustomerMaster', {
				params: { customer }
			})
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return err;
			});
	} else {
		return {};
	}
};

export const addCustomerGeneralAdmin = (user) => {
	return (
		axios
			// .post('/users/addCustomerGeneralAdmin', {
			.post(baseURL + '/users/addCustomerGeneralAdmin', {
				params: { user }
			})
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return err;
			})
	);
};

const _wait = (ms, value) => {
	return new Promise((resolve) => setTimeout(resolve, ms, value));
};
