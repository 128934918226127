import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { NonAuthRoutes } from '../config/AuthRoutesLogic';
import { useGlobalAuthContext } from '../context/authContext';
import LoadingApp from './LoadingApp';

const AuthRoute = ({
	Component,
	path,
	exact = false,
	requiredRoles
}) => {
	const isAuthed = !!useGlobalAuthContext().user.email;

	const userRole = useGlobalAuthContext().user.type;
	const loading = useGlobalAuthContext().loading;

	const userHasRequiredRole = requiredRoles.includes(userRole);
	const message = 'Please log in to view this page';
	return loading ? (
		<LoadingApp />
	) : (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				isAuthed && userHasRequiredRole ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							// pathname: userHasRequiredRole
							// 	? NonAuthRoutes.login
							// 	: NonAuthRoutes.unauthorized,
							pathname: NonAuthRoutes.login,
							state: {
								message,
								requestedPath: path
							}
						}}
					/>
				)
			}
		/>
	);
};

export default AuthRoute;
