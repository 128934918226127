export const ModalStyles = {
	content: {
		position: 'relative',
		width: '500px',
		background: '#efefef',
		border: '1px solid #ccc',
		//transition: '1.1s ease-out',
		filter: 'blur(0)',
		//transform: 'scale(1)',
		opacity: 1,
		visibility: 'visible',
		boxShadow: '-2rem 2rem 2rem rgba(0, 0, 0, 0.5)',
		outline: 0,
		backgroundColor: 'rgba(255, 255, 255)',
		color: 'rgba(0, 0, 0, 0.8)',
		borderRadius: '10px'
	},
	overlay: {
		backgroundColor: 'rgba(255, 255, 255, 0.9)'
	}
};
