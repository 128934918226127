import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GlobalCustomerContext = createContext();
const GlobalCustomerActionsContext = createContext();

export const useGlobalCustomerContext = () =>
	useContext(GlobalCustomerContext);
export const useGlobalCustomerActionsContext = () =>
	useContext(GlobalCustomerActionsContext);

const GlobalCustomerContextProvider = (props) => {
	const [customerData, setCustomerData] = useState({
		selectedCustomer: {
			id: '',
			name: '',
			master: [],
			users: []
		}
	});

	return (
		<GlobalCustomerContext.Provider value={customerData}>
			<GlobalCustomerActionsContext.Provider value={setCustomerData}>
				{props.children}
			</GlobalCustomerActionsContext.Provider>
		</GlobalCustomerContext.Provider>
	);
};

GlobalCustomerContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default GlobalCustomerContextProvider;
