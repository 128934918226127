import React, { useEffect, useState } from 'react';
import {
	addUser,
	deleteUser,
	getInternalUsers,
	updateUser
} from './API_Functions/UserFunctions';
import { useAlert, positions } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty } from '../utils/utils';
import { useGlobalAuthContext } from '../context/authContext';
import ReactTimeAgo from 'react-time-ago';

const initialInputState = {
	userEmail: '',
	userFirstName: '',
	userLastName: '',
	userType: ''
};

function SiteAdmin() {
	const [inputState, setInputState] = React.useState(
		initialInputState
	);

	const [internalUsers, setInternalUsers] = useState([]);
	const [editingAdmin, setEditingAdmin] = useState(false);
	const [selectedEditAdmin, setSelectedEditAdmin] = useState({});

	const authData = useGlobalAuthContext();
	const userType = authData.user.type;
	const userEmail = authData.user.email;

	const alert = useAlert();

	const getInternalUsersAsync = () => {
		getInternalUsers().then((r) => {
			if (r?.data?.internalUsers) {
				console.log();
				setInternalUsers(r.data.internalUsers);
			} else {
				if (r.status === 401) {
				}
			}
		});
	};

	useEffect(() => {
		getInternalUsersAsync();
	}, []);

	const handleInputChange = (e) => {
		const value = e.target.value;
		if (!editingAdmin) {
			setInputState({
				...inputState,
				[e.target.name]: value
			});
		} else {
			setSelectedEditAdmin({
				...selectedEditAdmin,
				[e.target.name]: value
			});
		}
	};

	const handleRadioChange = (e) => {
		const val = e.target.value;
		if (!editingAdmin) {
			setInputState({ ...inputState, userType: val });
		} else {
			setSelectedEditAdmin({ ...selectedEditAdmin, userType: val });
		}
	};

	const addNewInternalUser = (e) => {
		e.preventDefault();
		// should definitely add a proper form validation library to this such
		// as Formik - https://formik.org/
		// if (userType === 'moderator') {
		// 	inputState.userType = 'moderator';
		// }
		if (
			isEmpty(inputState.userLastName) ||
			isEmpty(inputState.userFirstName) ||
			isEmpty(inputState.userEmail) ||
			isEmpty(inputState.userType)
		) {
			alert.show(`Please fill in all the inputs!`, {
				timeout: 5000, // custom timeout just for this one alert
				type: 'error',
				position: positions.TOP_CENTER
			});
		} else {
			const customer = {
				email: inputState.userEmail,
				first_name: inputState.userFirstName,
				last_name: inputState.userLastName,
				customerName: null,
				userType: inputState.userType
			};
			addUser(customer)
				.then((r) => {
					if (r?.data?.user?.id) {
						const fullName = `${r.data.user.first_name} ${r.data.user.last_name}`;
						// show message to the user that the site admin was created
						const userType =
							r.data.user.type === 'internal-user'
								? 'user'
								: r.data.user.type;
						alert.show(
							`Site ${userType} "${fullName}" added and email invite sent to ${r.data.user.email}!`,
							{
								timeout: 7000, // custom timeout just for this one alert
								type: 'success',
								position: positions.TOP_CENTER,
								offset: '56px'
							}
						);
						setInputState(initialInputState);
						getInternalUsersAsync();
					}
				})
				.catch((e) => console.log(e));
		}
	};

	const deleteUserAsync = (id, first, last) => {
		deleteUser(id).then((r) => {
			getInternalUsers().then((r) => {
				setInternalUsers(r.data.internalUsers);
				alert.show(`User "${first} ${last}" deleted!`, {
					timeout: 5000, // custom timeout just for this one alert
					type: 'success',
					position: positions.TOP_CENTER,
					offset: '56px'
				});
			});
		});
	};

	const deleteUserClick = (id, first, last) => {
		confirmAlert({
			title: 'Confirm Delete User',
			message:
				'Are you sure you want to delete user ' +
				first +
				' ' +
				last +
				'?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteUserAsync(id, first, last)
				},
				{
					label: 'No'
				}
			]
		});
	};

	const editInternalUserClick = (user) => {
		setSelectedEditAdmin({
			id: user.userID,
			email: user.email,
			firstName: user.first_name,
			lastName: user.last_name,
			userType: user.type
		});
		setEditingAdmin(true);
	};

	const cancelEditing = () => {
		setEditingAdmin(false);
		setSelectedEditAdmin({});
		setInputState(initialInputState);
	};

	const editInternalUser = (e) => {
		e.preventDefault();
		updateUser(selectedEditAdmin).then((r) => {
			if (r.status === 200) {
				alert.show(
					`User ${selectedEditAdmin.firstName} ${selectedEditAdmin.lastName} updated!`,
					{
						timeout: 5000, // custom timeout just for this one alert
						type: 'success',
						position: positions.TOP_CENTER
					}
				);
				getInternalUsersAsync();
				setEditingAdmin(false);
			}
		});
	};

	return (
		<div className={'wrap'}>
			<div className="container site-admin">
				<div className="row">
					<div className="col-md-7 mt-3 mx-auto">
						<h3 className={'text-center'}>
							{!editingAdmin
								? 'Add New User'
								: `Edit ${selectedEditAdmin.firstName} ${selectedEditAdmin.lastName}`}
						</h3>
						<form className={'new-user position-relative'}>
							<div className={'form-group row mb-2'}>
								<label
									htmlFor={'userEmail'}
									className={'col-sm-3 col-form-label'}
								>
									Email:
								</label>
								<div className={'col-sm-8'}>
									<input
										type={'text'}
										className={'form-control'}
										id={'userEmail'}
										name={!editingAdmin ? 'userEmail' : 'email'}
										value={
											!editingAdmin
												? inputState.userEmail
												: selectedEditAdmin.email
										}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							<div className={'form-group row mb-2'}>
								<label
									htmlFor={'userFirstName'}
									className={'col-sm-3 col-form-label '}
								>
									First Name:
								</label>
								<div className={'col-sm-8'}>
									<input
										type={'text'}
										className={'form-control'}
										id={'userFirstName'}
										name={
											!editingAdmin ? 'userFirstName' : 'firstName'
										}
										value={
											!editingAdmin
												? inputState.userFirstName
												: selectedEditAdmin.firstName
										}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							<div className={'form-group row mb-2'}>
								<label
									htmlFor={'userLastName'}
									className={'col-sm-3 col-form-label'}
								>
									Last Name:
								</label>
								<div className={'col-sm-8'}>
									<input
										type={'text'}
										className={'form-control'}
										id={'userLastName'}
										name={!editingAdmin ? 'userLastName' : 'lastName'}
										value={
											!editingAdmin
												? inputState.userLastName
												: selectedEditAdmin.lastName
										}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							{(userType === 'admin' || userType === 'moderator') && (
								<div className={'form-group row'}>
									<label className={'col-sm-3 col-form-label'}>
										User Type:
									</label>
									<div className={'col-sm-9'}>
										{userType === 'admin' && (
											<div className={'form-check form-check-inline'}>
												<label htmlFor={'admin'} className={'radio'}>
													<input
														type={'radio'}
														name={'rdo'}
														id={'admin'}
														className={'hidden'}
														value={'admin'}
														checked={
															!editingAdmin
																? inputState.userType === 'admin'
																: selectedEditAdmin.userType ===
																  'admin'
														}
														onChange={handleRadioChange}
													/>
													<span className={'label'} />
													Admin
												</label>
											</div>
										)}
										<div className={'form-check form-check-inline'}>
											<label
												htmlFor={'moderator'}
												className={'radio'}
											>
												<input
													type={'radio'}
													name={'rdo'}
													id={'moderator'}
													className={'hidden'}
													value={'moderator'}
													checked={
														!editingAdmin
															? inputState.userType === 'moderator'
															: selectedEditAdmin.userType ===
															  'moderator'
													}
													onChange={handleRadioChange}
												/>
												<span className={'label'} />
												Moderator
											</label>
										</div>
										<div className={'form-check form-check-inline'}>
											<label
												htmlFor={'internal-user'}
												className={'radio'}
											>
												<input
													type={'radio'}
													name={'rdo'}
													id={'internal-user'}
													className={'hidden'}
													value={'internal-user'}
													checked={
														!editingAdmin
															? inputState.userType ===
															  'internal-user'
															: selectedEditAdmin.userType ===
															  'internal-user'
													}
													onChange={handleRadioChange}
												/>
												<span className={'label'} />
												Coop User
											</label>
										</div>
										<div className={'form-check form-check-inline'}>
											<label htmlFor={'vendor'} className={'radio'}>
												<input
													type={'radio'}
													name={'rdo'}
													id={'vendor'}
													className={'hidden'}
													value={'vendor'}
													checked={
														!editingAdmin
															? inputState.userType === 'vendor'
															: selectedEditAdmin.userType ===
															  'vendor'
													}
													onChange={handleRadioChange}
												/>
												<span className={'label'} />
												Vendor
											</label>
										</div>
									</div>
								</div>
							)}
							<div
								className={
									'col-md-6 mx-auto d-flex justify-content-between'
								}
							>
								<div className={'mx-auto'}>
									<input
										type={'submit'}
										className={
											'btn btn-lg btn-primary d-block mx-auto'
										}
										value={'Submit'}
										onClick={
											!editingAdmin
												? addNewInternalUser
												: editInternalUser
										}
									/>
								</div>
								{editingAdmin && (
									<button
										type={'button'}
										className={'btn btn-lg btn-danger mx-auto'}
										onClick={cancelEditing}
									>
										Cancel Editing
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
				<div className="row mt-5">
					<div className="col-md-8 mt-3 mx-auto">
						<h3 className={'text-center'}>
							List of Admins, Moderators, Coop Users, and Vendors
						</h3>
						{internalUsers && (
							<table
								className={'usersAndMods table table-hover w-100'}
							>
								<tbody>
									{internalUsers.map((u, ind) => {
										return (
											<tr
												key={ind}
												className={`border${
													userEmail === u.email ? ' currentUser' : ''
												}`}
											>
												<td
													className={
														userEmail === u.email ? 'fw-bold' : ''
													}
												>
													{u.first_name} {u.last_name}
												</td>
												<td>
													{u.email} - {u.type}
												</td>
												<td>
													Logins: ({u.logged_in_count || 0} &ndash;{' '}
													{u.last_logged_in ? (
														<ReactTimeAgo date={u.last_logged_in} />
													) : (
														'never'
													)}
													)
												</td>
												<td className={'text-muted fst-italic'}>
													{u.status}
												</td>
												<td>
													{(userType === 'admin' ||
														u.type === 'admin') && (
														<button
															className={'btn btn-sm btn-warning'}
															onClick={() => editInternalUserClick(u)}
														>
															Edit
														</button>
													)}
												</td>
												<td>
													{(userType === 'admin' ||
														userType === 'moderator') && (
														<button
															className={'btn btn-sm btn-danger'}
															onClick={() =>
																deleteUserClick(
																	u.userID,
																	u.first_name,
																	u.last_name
																)
															}
															disabled={userEmail === u.email}
														>
															Delete
														</button>
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SiteAdmin;
