import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GlobalModalContext = createContext();
const GlobalModalActionsContext = createContext();

export const useGlobalModalContext = () =>
	useContext(GlobalModalContext);
export const useGlobalModalActionsContext = () =>
	useContext(GlobalModalActionsContext);

const GlobalModalContextProvider = (props) => {
	const [modalData, setModalData] = useState({
		open: false,
		addUserType: ''
	});

	return (
		<GlobalModalContext.Provider value={modalData}>
			<GlobalModalActionsContext.Provider value={setModalData}>
				{props.children}
			</GlobalModalActionsContext.Provider>
		</GlobalModalContext.Provider>
	);
};

GlobalModalContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default GlobalModalContextProvider;
