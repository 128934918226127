import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserSettingsDropdown from './UserSettingsDropdown';
import { logout } from './API_Functions/UserFunctions';
import { useGlobalCustomerActionsContext } from '../context/customerContext';
import {
	useGlobalAuthActionsContext,
	useGlobalAuthContext
} from '../context/authContext';
import { capitalize } from '../utils/utils';
import logo from '../img/ecgrow-logo-w.png';

function debounce(fn, ms) {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}

const Navbar = () => {
	const [showUserDropdown, setShowUserDropdown] = useState(false);
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const [navbarClasses, setNavbarClasses] = useState(
		'navbar-collapse collapse'
	);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

	let history = useHistory();
	const wrapperRef = useRef(null);
	const hamburger = useRef(null);

	const setSelectedCustomer = useGlobalCustomerActionsContext();
	const authData = useGlobalAuthContext();
	const setAuthData = useGlobalAuthActionsContext();

	const userType = authData?.user?.type;
	const email = authData?.user?.email;
	let username = email ? email.slice(0, email.indexOf('@')) : '';

	username =
		username.length > 8 ? username.slice(0, 8) + '...' : username;

	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setIsMobile(window.innerWidth < 992);
		}, 1000);

		window.addEventListener('resize', debouncedHandleResize);

		return (_) => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	});

	const navbar = useRef(null);

	const handleNavCollapse = (e, show = true) => {
		if (isMobile) {
			if (isNavCollapsed && show) {
				setNavbarClasses('navbar-collapse collapse show');
			} else {
				setNavbarClasses('navbar-collapse collapse');
			}
			if (show) {
				setIsNavCollapsed(!isNavCollapsed);
			}
		}
	};

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowUserDropdown(false);
				}
			}

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	}

	useOutsideAlerter(wrapperRef);

	const logOut = (e) => {
		e.preventDefault();

		// call the logout router from app.js
		logout().then((r) => {
			if (r?.status === 200) {
				localStorage.clear();
				history.push(`/`);
				setSelectedCustomer('');
				setShowUserDropdown(false);
				setAuthData((prevState) => {
					return { ...prevState, loading: false, user: {} };
				});
			}
		});
	};

	const loginRegLink = (
		<ul className="navbar-nav">
			<li className={'desktop-icon'}>
				<img src={logo} alt={'EC Grow'} />
			</li>
			<li className="nav-item">
				<Link to="/" className="nav-link">
					Login
				</Link>
			</li>
		</ul>
	);

	const userLink = authData?.user?.email && (
		<ul className="navbar-nav">
			<li className={'desktop-icon'}>
				<img src={logo} alt={'EC Grow'} />
			</li>
			{(authData.user.type === 'admin' ||
				authData.user.type === 'moderator') && (
				<li className={'nav-item'}>
					<Link
						to={'/site-admin'}
						className={'nav-link'}
						onClick={handleNavCollapse}
					>
						Site Admin
					</Link>
				</li>
			)}
			{(authData.user.type === 'admin' ||
				authData.user.type === 'moderator') && (
				<>
					<li className={'nav-item'}>
						<Link
							to={'/customer'}
							className={'nav-link'}
							onClick={handleNavCollapse}
						>
							Customers
						</Link>
					</li>
					<li className={'nav-item'}>
						<Link
							to={'/regulatory'}
							className={'nav-link'}
							onClick={handleNavCollapse}
						>
							Regulatory
						</Link>
					</li>
				</>
			)}
			{(authData.user.type === 'customer-master' ||
				authData.user.type === 'customer-admin') && (
				<li className={'nav-item'}>
					<Link
						to={'/customer-admin'}
						className={'nav-link'}
						onClick={handleNavCollapse}
					>
						Admin
					</Link>
				</li>
			)}
			<li className={'nav-item'}>
				<Link
					to={'/sds-files'}
					className={'nav-link'}
					onClick={handleNavCollapse}
				>
					Safety Data Sheets
				</Link>
			</li>
			<li className={'nav-item user-settings'}>
				<button
					onClick={() => setShowUserDropdown(!showUserDropdown)}
					className={'nav-link btn'}
				>
					{authData.user.email}{' '}
					<FontAwesomeIcon
						className={'user-settings-down'}
						icon={faAngleDown}
						size={'1x'}
					/>
				</button>
			</li>
		</ul>
	);

	return (
		<>
			<nav
				className={
					'navbar fixed-top navbar-expand-lg navbar-dark bg-dark'
				}
			>
				<div className={'container-fluid'}>
					<button
						className="navbar-toggler"
						type="button"
						// data-bs-toggle="collapse"
						// data-bs-target="#navbar"
						aria-controls="navbar"
						// aria-expanded={!isNavCollapsed}
						aria-label="Toggle navigation"
						onClick={handleNavCollapse}
						ref={hamburger}
					>
						<span className="navbar-toggler-icon" />
					</button>

					<img src={logo} className={'mobile-icon'} alt={'EC Gro'} />

					<div className={navbarClasses} id={'navbar'} ref={navbar}>
						{authData?.user?.email ? userLink : loginRegLink}
						{/*	TODO: maybe in the future, show a loading spinner instead of nothing when authData.loading */}
					</div>
					{userType && (
						<button
							onClick={(e) => {
								setShowUserDropdown(!showUserDropdown);
								handleNavCollapse(e, false);
							}}
							className={'btn mobile-user-btn'}
						>
							<div className={'d-none d-sm-inline'}>{email}</div>
							<div className={'d-inline d-sm-none'}>
								{username}
							</div>{' '}
							<FontAwesomeIcon
								className={'user-settings-down'}
								icon={faAngleDown}
								size={'1x'}
							/>
						</button>
					)}
				</div>
			</nav>
			{showUserDropdown && (
				<UserSettingsDropdown
					logout={logOut}
					userType={capitalize(userType)}
					onClose={() => setShowUserDropdown(false)}
				/>
			)}
		</>
	);
};

export default withRouter(Navbar);
