import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GlobalAuthContext = createContext();
const GlobalAuthActionsContext = createContext();

export const useGlobalAuthContext = () =>
	useContext(GlobalAuthContext);
export const useGlobalAuthActionsContext = () =>
	useContext(GlobalAuthActionsContext);

const GlobalAuthContextProvider = (props) => {
	const [authData, setAuthData] = useState({
		loading: true,
		user: {
			email: '',
			type: '',
			customer: '',
			first: ''
		}
	});

	return (
		<GlobalAuthContext.Provider value={authData}>
			<GlobalAuthActionsContext.Provider value={setAuthData}>
				{props.children}
			</GlobalAuthActionsContext.Provider>
		</GlobalAuthContext.Provider>
	);
};

GlobalAuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default GlobalAuthContextProvider;
