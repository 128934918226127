export const detectBrowser = () => {
	let browser = null;
	// Opera 8.0+
	/*eslint-disable no-undef*/
	const isOpera =
		(!!window.opr && !!opr.addons) ||
		!!window.opera ||
		navigator.userAgent.indexOf(' OPR/') >= 0;

	// Firefox 1.0+
	const isFirefox = typeof InstallTrigger !== 'undefined';

	// Safari 3.0+ "[object HTMLElementConstructor]"
	const isSafari =
		/constructor/i.test(window.HTMLElement) ||
		(function (p) {
			return p.toString() === '[object SafariRemoteNotification]';
		})(
			!window['safari'] ||
				(typeof safari !== 'undefined' && safari.pushNotification)
		);
	/*eslint-enable no-undef*/
	// Internet Explorer 6-11
	const isIE = /*@cc_on!@*/ false || !!document.documentMode;

	// Edge 20+
	const isEdge = !isIE && !!window.StyleMedia;

	// Chrome 1 - 71
	const isChrome =
		!!window.chrome &&
		(!!window.chrome.webstore || !!window.chrome.runtime);

	// Blink engine detection
	const isBlink = (isChrome || isOpera) && !!window.CSS;

	if (isOpera) {
		browser = 'Opera';
	} else if (isFirefox) {
		browser = 'Firefox';
	} else if (isSafari) {
		browser = 'Safari';
	} else if (isIE) {
		browser = 'IE';
	} else if (isEdge) {
		browser = 'Edge';
	} else if (isChrome) {
		browser = 'Chrome';
	} else if (isBlink) {
		browser = 'Blink';
	} else {
		browser = 'Unknown';
	}
	return browser;
};

export const getCookie = (name) => {
	const cookie = document.cookie;
	const prefix = name + '=';
	let begin = cookie.indexOf('; ' + prefix);
	let end = -1;
	if (begin === -1) {
		begin = cookie.indexOf(prefix);
		if (begin !== 0) return null;
	} else {
		begin += 2;
		end = document.cookie.indexOf(';', begin);
		if (end === -1) {
			end = cookie.length;
		}
	}
	return unescape(cookie.substring(begin + prefix.length, end));
};

// determine if a string is empty or just white space
export const isEmpty = (text) => {
	return text === null || text.match(/^ *$/) !== null;
};

export const capitalize = (word) => {
	return word.charAt(0).toUpperCase() + word.slice(1);
};
/*** date functions ***/

// return either st, nd, or rd depending on the number passed in
function nth(n) {
	return (
		['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
	);
}

// format a date correctly to go into a html date input box
// e.g. 2020-05-03
export const dateFormatted = (d) => {
	let month = '' + (d.getMonth() + 1);
	let day = '' + d.getDate();
	const year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

// format a date like mm/dd/yyyy
// e.g. 05/12/2020
export const getFormattedDate = (
	date,
	includeTime = false,
	addFive = true
) => {
	if (Object.prototype.toString.call(date) !== '[object Date]') {
		// passed in date is not an actual date
		date = new Date(date);
	}

	if (addFive) {
		// 8-3-20 added this line to add 5 hours to the incoming date because of the time being off 5 hours because of timezone stuff
		date.setHours(date.getHours() + 5);
	}
	const year = date.getFullYear();
	const month = (1 + date.getMonth()).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	let returnStr = month + '/' + day + '/' + year;
	if (includeTime) {
		let hours = date.getUTCHours();
		const minutes = date.getUTCMinutes().toString().padStart(2, '0');
		const seconds = date.getUTCSeconds().toString().padStart(2, '0');
		let ampm = 'am';
		if (hours > 11) {
			hours = hours === 12 ? hours : hours - 12;
			ampm = 'pm';
		}
		hours = hours.toString().padStart(2, '0');
		const timeStr = `${hours}:${minutes}:${seconds} ${ampm}`;

		returnStr += ' ' + timeStr;
	}
	return returnStr;
};

const addMinutes = (date, minutes) => {
	return new Date(date.getTime() + minutes * 60000);
};

// format a date object like August 2nd, 2008 11:49AM or without the time
// e.g. August 2nd, 2008 11:49AM
export const prettyDateTime = (d, includeTime = true) => {
	if (!(d instanceof Date) || isNaN(d.getTime())) {
		d = new Date();
	}

	const updatedDate = addMinutes(d, d.getTimezoneOffset());

	// const month = d.toLocaleString('default', { month: 'short' });
	// const year = d.getFullYear();
	// const day = d.getDate() + nth(d.getDate());
	const month = updatedDate.toLocaleString('default', {
		month: 'short',
	});
	const year = updatedDate.getFullYear();
	const day = updatedDate.getDate() + nth(updatedDate.getDate());
	let returnStr = `${month} ${day}, ${year}`;
	if (includeTime) {
		const hours =
			updatedDate.getHours() < 13
				? updatedDate.getHours() === 0
					? 12
					: updatedDate.getHours()
				: updatedDate.getHours() - 12;

		const minutes = updatedDate
			.getMinutes()
			.toString()
			.padStart(2, '0');
		const ampm = updatedDate.getHours() > 11 ? 'PM' : 'AM';
		returnStr += ` ${hours}:${minutes}${ampm}`;
	}
	return returnStr;
};
