import React, { createRef, useEffect, useState } from 'react';
import { detect } from 'detect-browser';
import {
	getCustomerFiles,
	getCustomerFromUser
} from './API_Functions/UserFunctions';
import {
	getChemicalLoads,
	getChemicals
} from './API_Functions/ChemicalFunctions';
import { useGlobalAuthContext } from '../context/authContext';
import { getCustomersFromDB } from '../utils/commonFunctions';
import { getFormattedDate } from '../utils/utils';
import pdfLogo from '../img/pdf-icon.png';
import SdsFilesHeader from './SDSFilesHeader';
import SDSDataTable from './SDSDataTable';
import Pagination from './Pagination';
import LoadingApp from './LoadingApp';
import Footer from './Footer';
import { userRoles, UserRoles } from '../config/typedRoles';

// max number of SDS's per page
const pageLimit = 10;

const SDSFiles = () => {
	const [allCustomers, setAllCustomers] = useState([]);
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedChemical, setSelectedChemical] = useState('');
	const [selectedLoad, setSelectedLoad] = useState('');
	const [chemicals, setChemicals] = useState([]);
	const [loads, setLoads] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState('');
	const [gettingCustomers, setGettingCustomers] = useState(true);
	const [gettingFiles, setGettingFiles] = useState(false);
	const [productSearchText, setProductSearchText] = useState('');
	const [gridRows, setGridRows] = useState([]);
	const [hoveredButton, setHoveredButton] = useState('');
	const [sortBy, setSortBy] = useState('name');
	const [sortDir, setSortDir] = useState('asc');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [productCountTitle, setProductCountTitle] = useState('');

	const authData = useGlobalAuthContext();
	const userType = authData.user.type;

	const productsList = createRef();

	const browser = detect();
	const os = browser.os.toLowerCase();

	const pageNum = `Page ${currentPage}`;

	const noValidCustomer =
		!userRoles.internalUsers.includes(userType) &&
		!userRoles.vendors.includes(userType) &&
		selectedCustomer === '';

	useEffect(() => {
		if (userRoles.customersAll.includes(userType)) {
			// setGettingCustomers(true);
			getCustomerFromUser()
				.then((r) => {
					if (r.status === 200) {
						setSelectedCustomer(r.data.customer.customer_name);
						setGettingCustomers(false);
					}
				})
				.catch((e) => {
					// console.log(e);
					setGettingCustomers(false);
				});
		} else if (userRoles.internalUsers.includes(userType)) {
			// setGettingCustomers(true);
			getCustomersFromDB().then((r) => {
				setAllCustomers(r);
				if (!userRoles.internalUsers.includes(userType)) {
					getCustomerFromUser()
						.then((r) => {
							if (r.status === 200) {
								setSelectedCustomer(r.data.customer.customer_name);
								setGettingCustomers(false);
							}
						})
						.catch((e) => {
							console.log(e);
						});
				} else {
					setGettingCustomers(false);
				}
			});
		} else if (userRoles.vendors.includes(userType)) {
			setSelectedCustomer('all');
			getCustomerFiles('all', null, null, true)
				.then((r) => {
					setSortBy('name');
					setSortDir('asc');
					const files = r.data.files;
					files.sort(productSorter(true, 'sheet_name'));
					setProducts(files);
					setTotalRecords(files.length);

					const offset = (currentPage - 1) * pageLimit;
					const newFilteredProducts = files.slice(
						offset,
						offset + pageLimit
					);
					setFilteredProducts(newFilteredProducts);
					// const rowsArray = setProductsArray(files);
					const rowsArray = setProductsArray(
						newFilteredProducts,
						false
					);
					// this is wrong, don't sort after we slice up the list, do it before!!!
					// rowsArray.sort(productSorter(sortDir === 'asc', sortBy));
					setGridRows(rowsArray);
					const totalPagesNum = Math.max(
						1,
						Math.ceil(files.length / pageLimit)
					);
					setProductCountTitle(getProductsTitle(files, false, 1));
					setGettingFiles(false);
					setGettingCustomers(false);
				})
				.catch((e) => {
					setGettingFiles(false);
					setGettingCustomers(false);
				});
		} else {
			setGettingCustomers(false);
		}
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.className = '';
		};
	}, []);

	const setProductsArray = (products, sort = true) => {
		let filteredProducts = [...products];
		if (productSearchText.length > 2) {
			filteredProducts = products.filter((product) => {
				if (
					!productSearchText ||
					productSearchText.length < 3 ||
					(productSearchText.length >= 3 &&
						product.sheet_name
							.toLowerCase()
							.includes(productSearchText.toLowerCase())) ||
					product.brand
						.toLowerCase()
						.includes(productSearchText.toLowerCase()) ||
					product.chemical
						.toLowerCase()
						.includes(productSearchText.toLowerCase())
				) {
					return true;
				}
			});
		}
		let productsArr = filteredProducts.map((f, ind) => {
			return {
				id: ind,
				pdfIcon: (
					<img
						src={pdfLogo}
						className={'pdf-icon'}
						alt={'Download PDF'}
					/>
				),
				name: f.sheet_name,
				brand: f.brand || 'Award',
				chemical: f.chemical,
				load: f.load,
				date: getFormattedDate(f.updated_at)
			};
		});
		if (sort) {
			productsArr.sort((a, b) => (a.name > b.name ? 1 : -1));
		}
		return productsArr;
	};

	const getProductsTitle = (products, useSearch = true) => {
		if (products) {
			const totalProductsLength = useSearch
				? products.filter((product) => {
						if (
							!productSearchText ||
							productSearchText.length < 3 ||
							(productSearchText.length >= 3 &&
								product.sheet_name
									.toLowerCase()
									.includes(productSearchText.toLowerCase())) ||
							product.brand
								.toLowerCase()
								.includes(productSearchText.toLowerCase()) ||
							product.chemical
								.toLowerCase()
								.includes(productSearchText.toLowerCase())
						) {
							return true;
						}
				  }).length
				: products.length;

			if (selectedCustomer !== 'all') {
				return (
					totalProductsLength +
					` product${products.length !== 1 ? 's' : ''}` +
					` for ${selectedCustomer}${
						productSearchText.length > 2
							? ' ( search: ' + productSearchText + ' )'
							: ''
					}`
				);
			} else {
				return (
					totalProductsLength +
					` product${
						products.length !== 1 ? 's' : ''
					} for All Customers`
				);
			}
		}
		return '';
	};

	useEffect(() => {
		const offset = 0;
		if (productSearchText.length > 2) {
			setCurrentPage(1);

			const totalProductsArr = products.filter(
				(p) =>
					p.sheet_name
						.toLowerCase()
						.includes(productSearchText.toLowerCase()) ||
					p.brand
						.toLowerCase()
						.includes(productSearchText.toLowerCase()) ||
					p.chemical
						.toLowerCase()
						.includes(productSearchText.toLowerCase())
			);

			const filteredProductsArr = products
				.filter(
					(p) =>
						p.sheet_name
							.toLowerCase()
							.includes(productSearchText.toLowerCase()) ||
						p.brand
							.toLowerCase()
							.includes(productSearchText.toLowerCase()) ||
						p.chemical
							.toLowerCase()
							.includes(productSearchText.toLowerCase())
				)
				.slice(offset, offset + pageLimit);
			setTotalRecords(totalProductsArr.length);
			const rowsArray = setProductsArray(filteredProductsArr);
			setFilteredProducts(filteredProductsArr);
			setGridRows(rowsArray);
			setProductCountTitle(
				getProductsTitle(totalProductsArr, true, 1)
			);
		} else if (gridRows.length !== products.length) {
			setCurrentPage(1);
			setTotalRecords(products.length);

			const newFilteredProducts = products.slice(
				offset,
				offset + pageLimit
			);
			setFilteredProducts(newFilteredProducts);
			const rowsArray = setProductsArray(newFilteredProducts, false);
			rowsArray.sort(productSorter(sortDir === 'asc', sortBy));
			setGridRows(rowsArray);
			setProductCountTitle(getProductsTitle(products, false, 1));
		}
	}, [productSearchText]);

	useEffect(() => {
		setProductSearchText('');
		setCurrentPage(1);
		(async () => {
			if (selectedCustomer.length) {
				setGettingFiles(true);
				setGridRows([]);
				await getCustomerFiles(selectedCustomer, null, null, true)
					.then((r) => {
						setSortBy('name');
						setSortDir('asc');
						const files = r.data.files;
						files.sort(productSorter(true, 'sheet_name'));
						setProducts(files);
						setTotalRecords(files.length);

						const offset = (currentPage - 1) * pageLimit;
						const newFilteredProducts = files.slice(
							offset,
							offset + pageLimit
						);
						setFilteredProducts(newFilteredProducts);
						// const rowsArray = setProductsArray(files);
						const rowsArray = setProductsArray(
							newFilteredProducts,
							false
						);
						// this is wrong, don't sort after we slice up the list, do it before!!!
						// rowsArray.sort(productSorter(sortDir === 'asc', sortBy));
						setGridRows(rowsArray);
						const totalPagesNum = Math.max(
							1,
							Math.ceil(files.length / pageLimit)
						);
						setProductCountTitle(getProductsTitle(files, false, 1));
						setGettingFiles(false);
					})
					.catch((e) => {
						// console.log('Error getting the customer files: ', e);
						setGettingFiles(false);
					});
				// await getChemicals(selectedCustomer, false).then((r) => {
				// 	setChemicals(r.data.chemicals);
				// });
			} else {
				setProducts([]);
				setChemicals([]);
				setGridRows([]);
			}
			setSelectedChemical('');
			setSelectedLoad('');
		})();
	}, [selectedCustomer]);

	const selectCustomer = (e) => {
		e.preventDefault();
		console.log(e.target.value);
		setSelectedCustomer(e.target.value);
		setSelectedChemical('');
		setLoads([]);
	};

	const productSorter = (asc, key) => (a, b) => {
		if (asc) {
			return a[key] === b[key] ? 0 : a[key] > b[key] ? 1 : -1;
		} else {
			return a[key] === b[key] ? 0 : a[key] < b[key] ? 1 : -1;
		}
	};

	useEffect(() => {
		setCurrentPage(1);
		const rowsArray = setProductsArray(products, false);
		rowsArray.sort(productSorter(sortDir === 'asc', sortBy));
		const offset = (currentPage - 1) * pageLimit;
		const newFilteredProducts = rowsArray.slice(
			offset,
			offset + pageLimit
		);
		setFilteredProducts(newFilteredProducts);
		setGridRows(newFilteredProducts);
		setProductCountTitle(
			getProductsTitle(products, true, 1, totalPages)
		);
	}, [sortBy, sortDir]);

	const ProductsGrid = () => {
		return (
			<SDSDataTable data={gridRows} customer={selectedCustomer} />
		);
	};

	const onPageChanged = (data) => {
		const { currentPageNum, totalPages, pageLimit } = data;
		const offset = (currentPageNum - 1) * pageLimit;
		const sortedProducts = products
			.filter(
				(p) =>
					p.sheet_name
						.toLowerCase()
						.includes(productSearchText.toLowerCase()) ||
					p.brand
						.toLowerCase()
						.includes(productSearchText.toLowerCase()) ||
					p.chemical
						.toLowerCase()
						.includes(productSearchText.toLowerCase())
			)
			.sort(productSorter(sortDir === 'asc', sortBy));
		const newFilteredProducts = sortedProducts.slice(
			offset,
			offset + pageLimit
		);
		setFilteredProducts(newFilteredProducts);
		setTotalPages(totalPages);
		setCurrentPage(currentPageNum);
		const rowsArray = setProductsArray(newFilteredProducts, false);
		rowsArray.sort(productSorter(sortDir === 'asc', sortBy));
		setGridRows(rowsArray);
		productsList.current.scrollTo(0, 0);
		setProductCountTitle(
			getProductsTitle(products, true, currentPageNum, totalPages)
		);
		//this.setState({ currentPage, currentCountries, totalPages });
	};

	return (
		<div className={'wrap background-img sds-page'}>
			<SdsFilesHeader
				productSearchText={productSearchText}
				setProductSearchText={setProductSearchText}
				hoveredButton={hoveredButton}
				setHoveredButton={setHoveredButton}
				sortBy={sortBy}
				setSortBy={setSortBy}
				showSortCaret={gridRows.length > 0}
				sortDir={sortDir}
				setSortDir={setSortDir}
			/>
			<div className={'container products mt-1 p-1'}>
				{gettingCustomers || gettingFiles ? (
					<LoadingApp bgColor={'white'} />
				) : noValidCustomer ? (
					<h4 className={'text-center text-danger m-3'}>
						There is no customer associated with this user account.
						Please contact your admin.
					</h4>
				) : (
					<>
						{userRoles.internalUsers.includes(userType) && (
							<div className={'filters position-relative'}>
								<div className={'row'}>
									<label
										htmlFor="select-customer"
										className="col-sm-7 col-form-label"
									>
										View Products by Customer
									</label>
								</div>
								<div className={'row mb-1'}>
									<div className="col-sm-6">
										<select
											id={'select-customer'}
											className={'form-select'}
											onChange={selectCustomer}
											value={selectedCustomer}
										>
											<option value={''} key={'0'}>
												Select a customer...
											</option>
											{allCustomers.map((cust) => (
												<option key={cust.name}>{cust.name}</option>
											))}
										</select>
									</div>
								</div>
							</div>
						)}
						{selectedCustomer && products.length > 0 ? (
							<div
								className={`products-list${
									os === 'mac os' || os === 'ios' ? ' ios' : ''
								}`}
								ref={productsList}
							>
								<div className={'m-1 row'}>
									<div
										className={
											'col-8 fw-bold text-muted product-count'
										}
									>
										{productCountTitle}
									</div>
									<div className={'col-4 text-end'}>
										{totalPages > 1 && pageNum}
									</div>
								</div>
								<ProductsGrid />

								<Pagination
									totalRecords={totalRecords}
									pageLimit={pageLimit}
									pageNeighbours={1}
									onPageChanged={onPageChanged}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									setTotalPages={setTotalPages}
								/>
							</div>
						) : selectedCustomer ? (
							<h4 className={'m-2'}>
								No products available for {selectedCustomer}
							</h4>
						) : (
							<h4 className={'m-2'}>
								Please select a customer in the select list above
							</h4>
						)}
						{/*<a*/}
						{/*	className={'pdf-link'}*/}
						{/*	href={pdfURL}*/}
						{/*	target={'_blank'}*/}
						{/*	ref={pdfLink}*/}
						{/*>*/}
						{/*	pdf*/}
						{/*</a>*/}
					</>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default SDSFiles;
