import React from 'react';
import Logo404 from '../img/ecgrow_logo-404.png';
import UTurn from '../img/u-turn.png';
import { useGlobalAuthContext } from '../context/authContext';

const Error404 = (props) => {
	const user = useGlobalAuthContext().user;

	return (
		<div className={'error-404'}>
			<div className={'row'}>
				<div className={'col-6 offset-2'}>
					<img
						className={'img-fluid'}
						alt={'404 Error'}
						src={Logo404}
					/>
				</div>
			</div>
			<div className={'row'}>
				<div className={'col-6 offset-3'}>
					<h1 className={'error-404-msg'}>
						Oops! Looks like you made a wrong turn!
					</h1>
					<img
						className={'img-fluid u-turn mb-4'}
						alt={'U-Turn'}
						src={UTurn}
					/>
					{user.email ? (
						user.type === 'admin' || user.type === 'moderator' ? (
							<h4 className={'text-center'}>
								Please proceed to{' '}
								<a href={'/site-admin'}>Site Admin</a>,{' '}
								<a href={'/customer'}>Customers</a>,{' '}
								<a href={'/regulatory'}>Regulatory</a>, or{' '}
								<a href={'/sds-files'}>Safety Data Sheets</a>
							</h4>
						) : user.type === 'customer-master' ||
						  user.type === 'customer-admin' ? (
							<h4 className={'text-center'}>
								Please proceed to{' '}
								<a href={'/customer-admin'}>Admin</a> or{' '}
								<a href={'/sds-files'}>Safety Data Sheets</a>
							</h4>
						) : (
							<h4 className={'text-center'}>
								Please proceed to{' '}
								<a href={'/sds-files'}>Safety Data Sheets</a>
							</h4>
						)
					) : (
						<h4 className={'text-center'}>
							Please proceed to <a href={'/'}>Login</a>
						</h4>
					)}
				</div>
			</div>
		</div>
	);
};

export default Error404;
