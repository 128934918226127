import { getCustomers } from '../components/API_Functions/CustomerFunctions';

export const getCustomersFromDB = () => {
	return getCustomers()
		.then((res) => {
			const customers = res.data.customers;
			console.log({ customers });

			return customers
				.sort((a, b) => {
					return a.customer > b.customer
						? 1
						: b.customer > a.customer
						? -1
						: 0;
				})
				.map((c) => {
					return { id: c.id, name: c.customer, core: c.includeCore };
				});
		})
		.catch((e) => {
			return e;
		});
};

export const defaultFormState = {
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	phone: '',
	permissionType: '',
	userType: '',
	formType: 'new',
	id: '',
	customerName: '',
	includeCoreItems: true
};
