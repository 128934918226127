import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { downloadPDF } from './API_Functions/UserFunctions';
import ReactTooltip from 'react-tooltip';

const SdsDownloadButton = (props) => {
	const { product, productRef, customer } = props;

	return (
		<div>
			<FontAwesomeIcon
				className={'SDS-download'}
				icon={faLongArrowDown}
				size={'2x'}
				data-tip={'Click to download'}
				data-for={'download-tip'}
				onClick={(e) => downloadPDF(e, product, customer)}
				onMouseLeave={() => {
					ReactTooltip.show(productRef.current);
				}}
				onMouseEnter={() => {
					ReactTooltip.hide(productRef.current);
				}}
			/>
			<ReactTooltip
				id={'download-tip'}
				effect={'solid'}
				backgroundColor={'#212529'}
			/>
		</div>
	);
};

SdsDownloadButton.propTypes = {};

export default SdsDownloadButton;
