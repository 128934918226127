import axios from 'axios';

let { baseURL } = require('./EnvVars');

export const createCustomer = (customer, includeCore) => {
	return axios
		.post(baseURL + '/customers/createCustomer', {
			customer,
			includeCore
		})
		.then((res) => {
			return res;
		})
		.catch((e) => {
			return e;
		});
};

export const deleteCustomer = (customer) => {
	return axios
		.post(baseURL + '/customers/deleteCustomer', {
			customer
		})
		.then((res) => {
			return res;
		})
		.catch((e) => {
			return Promise.reject(e);
		});
};

export const getCustomers = async () => {
	try {
		return await axios.get(baseURL + '/customers/getCustomers');
	} catch (e) {
		return Promise.reject('getCustomers error' + e);
	}
};

export const updateCustomer = (customer) => {
	return axios
		.patch(baseURL + '/customers/updateCustomer', {
			customer
		})
		.then((res) => {
			return res;
		})
		.catch((e) => {
			return Promise.reject(e);
		});
};
