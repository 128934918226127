import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltDown } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import {
	downloadPDF,
	openPDFAsync
} from './API_Functions/UserFunctions';
import SdsDownloadButton from './SDSDownloadButton';

const genericAwardChemicals = [
	'bifenthrin',
	'imi-lambda',
	'mallet',
	'q3',
	'viper'
];

const SdsProductLink = (props) => {
	const { icon, product, customer } = props;

	const productLink = useRef();

	const [viewDownloadText, setViewDownloadText] = useState('');

	let type = product.chemical.replace(/\s+/g, '-').toLowerCase();
	if (genericAwardChemicals.includes(type)) {
		type += ' generic-award';
	}

	const openPDF = (name) => {
		try {
			openPDFAsync(name, customer);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div
			ref={productLink}
			className={'product d-flex'}
			key={product.id}
			onClick={() => openPDF(product.name)}
			data-tip={'Click to view'}
			data-for={'view-tip'}
		>
			<div className={'flex-shrink-1 align-self-center me-3'}>
				<img src={icon} className={'pdf-icon'} alt={'View PDF'} />
			</div>
			<div
				className={
					'flex-fill d-flex flex-column justify-content-around'
				}
			>
				<div className={'d-flex flex-row align-items-center'}>
					<div className={'brand'}>{product.brand}</div>
					<div className={`chemical ${type}`}>{product.chemical}</div>
				</div>
				<div className={'name'}>{product.name}</div>
			</div>
			{viewDownloadText && (
				<div className={'click-text'}>{viewDownloadText}</div>
			)}
			<div className={'d-flex flex-column justify-content-around'}>
				<div className={'load'}>Load: {product.load}</div>
				<div className={'date'}>Date Modified: {product.date}</div>
			</div>
			<SdsDownloadButton
				product={product.name}
				productRef={productLink}
				customer={customer}
			/>
			<ReactTooltip
				id={'view-tip'}
				effect={'solid'}
				backgroundColor={'#212529'}
			/>
		</div>
	);
};

SdsProductLink.propTypes = {
	customer: PropTypes.string.isRequired
};

export default SdsProductLink;
