import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const showExtraFeatures = true;

const LoginForm = (props) => {
	const {
		onLoginSubmit,
		email,
		setEmail,
		password,
		setPassword,
		showPasswordReset,
		rememberMe,
		setRememberMe
	} = props;

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisibility = () => {
		setPasswordShown(!passwordShown);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			onLoginSubmit(e);
		}
	};

	return (
		<form
			id={'login-form'}
			method={'POST'}
			noValidate
			onSubmit={onLoginSubmit}
		>
			<div className={'form-group'}>
				<label htmlFor={'email'}>Email address</label>
				<input
					type={'email'}
					className={'form-control mb-3'}
					name={'email'}
					placeholder={'Enter email'}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={handleKeyDown}
				/>
				<div className={'form-group position-relative'}>
					<label htmlFor={'password'}>Password</label>
					<input
						type={passwordShown ? 'text' : 'password'}
						className={'form-control mb-3'}
						name={'password'}
						placeholder={'Password'}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={handleKeyDown}
					/>
					{passwordShown ? (
						<i
							className={'login-eye'}
							onClick={togglePasswordVisibility}
						>
							{eyeSlash}
						</i>
					) : (
						<i
							className={'eye-slash login-eye'}
							onClick={togglePasswordVisibility}
						>
							{eye}
						</i>
					)}
				</div>
				{showExtraFeatures && (
					<div className={'row text-small remember-me-form mb-1'}>
						<div className={'col-6'}>
							<div className={'form-check d-flex align-items-end'}>
								<input
									type={'checkbox'}
									className={'form-check-input me-2'}
									id={'remember-me'}
									checked={rememberMe}
									onChange={() => setRememberMe(!rememberMe)}
								/>{' '}
								<label
									className={'form-check-label'}
									htmlFor={'remember-me'}
								>
									Remember Me
								</label>
							</div>
						</div>
						<div className={'col-6 text-end'}>
							<Link to={'/forgotPassword'}>
								Forgot your credentials?
							</Link>
						</div>
					</div>
				)}
				<div className={'row g-0'}>
					<button
						type={'submit'}
						id={'submit'}
						className={
							'col-12 btn btn-lg btn-primary-green text-white mt-1 mb-4'
						}
					>
						Log In
					</button>
				</div>
			</div>
		</form>
	);
};

LoginForm.propTypes = {
	onLoginSubmit: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	setEmail: PropTypes.func.isRequired,
	password: PropTypes.string.isRequired,
	setPassword: PropTypes.func.isRequired,
	showPasswordReset: PropTypes.func.isRequired
};

export default LoginForm;
