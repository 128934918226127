import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendarAlt,
	faFlask,
	faSearch,
	faCaretUp as faCaretUpFilled,
	faCaretDown as faCaretDownFilled
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faCaretUp, faTag } from '@fortawesome/pro-light-svg-icons';
import { faFunction } from '@fortawesome/pro-regular-svg-icons';

const SdsFilesHeader = (props) => {
	const {
		productSearchText,
		setProductSearchText,
		hoveredButton,
		setHoveredButton,
		sortBy,
		setSortBy,
		showSortCaret,
		sortDir,
		setSortDir
	} = props;
	return (
		<header>
			<h4>Safety Data Sheets</h4>
			<div className={'container py-0'}>
				<div
					className={'sds-search-container position-relative d-flex'}
				>
					<input
						type="text"
						className={'form-control sds-search'}
						value={productSearchText}
						onChange={(e) => setProductSearchText(e.target.value)}
						placeholder={'Search for SDS...'}
					/>
					{productSearchText.length > 0 &&
						productSearchText.length < 3 && (
							<div className={'text-hint'}>
								(type at least 3 characters)
							</div>
						)}
					<FontAwesomeIcon icon={faSearch} size={'1x'} />
					<div
						className={'clear-search'}
						onClick={() => setProductSearchText('')}
					>
						clear
					</div>
				</div>
				<p className={'mt-2'}>Sort by category</p>
				<div className={'d-flex justify-content-between'}>
					<button
						className={'btn position-relative'}
						onMouseEnter={(e) => setHoveredButton('Name')}
						onMouseLeave={() => setHoveredButton('')}
						onClick={(e) => {
							if (sortDir === 'desc' || sortBy !== 'name') {
								setSortDir('asc');
							} else {
								setSortDir('desc');
							}
							setSortBy('name');
						}}
					>
						<FontAwesomeIcon
							icon={faListAlt}
							className={'sort-icon'}
						/>
						Filename
						<FontAwesomeIcon
							icon={
								sortBy === 'name' && sortDir === 'asc'
									? faCaretUpFilled
									: faCaretUp
							}
							className={`caret-up ${
								showSortCaret &&
								showSortCaret &&
								((hoveredButton === 'Name' && sortBy !== 'name') ||
									(sortDir === 'asc' && sortBy === 'name'))
									? 'show'
									: 'hide'
							}`}
						/>
						<FontAwesomeIcon
							icon={faCaretDownFilled}
							className={`caret-down ${
								showSortCaret &&
								sortDir === 'desc' &&
								sortBy === 'name'
									? 'show'
									: 'hide'
							}`}
						/>
					</button>
					<button
						className={'btn position-relative'}
						onMouseEnter={(e) => setHoveredButton('Brand')}
						onMouseLeave={() => setHoveredButton('')}
						onClick={(e) => {
							if (sortDir === 'desc' || sortBy !== 'brand') {
								setSortDir('asc');
							} else {
								setSortDir('desc');
							}
							setSortBy('brand');
						}}
					>
						<FontAwesomeIcon icon={faTag} className={'sort-icon'} />
						Brand
						<FontAwesomeIcon
							icon={
								sortBy === 'brand' && sortDir === 'asc'
									? faCaretUpFilled
									: faCaretUp
							}
							className={`caret-up ${
								showSortCaret &&
								((hoveredButton === 'Brand' && sortBy !== 'brand') ||
									(sortDir === 'asc' && sortBy === 'brand'))
									? 'show'
									: 'hide'
							}`}
						/>
						<FontAwesomeIcon
							icon={faCaretDownFilled}
							className={`caret-down ${
								showSortCaret &&
								sortDir === 'desc' &&
								sortBy === 'brand'
									? 'show'
									: 'hide'
							}`}
						/>
					</button>
					<button
						className={'btn position-relative'}
						onMouseEnter={(e) => setHoveredButton('Chemical')}
						onMouseLeave={() => setHoveredButton('')}
						onClick={(e) => {
							if (sortDir === 'desc' || sortBy !== 'chemical') {
								setSortDir('asc');
							} else {
								setSortDir('desc');
							}
							setSortBy('chemical');
						}}
					>
						<FontAwesomeIcon icon={faFlask} className={'sort-icon'} />
						Type
						<FontAwesomeIcon
							icon={
								sortBy === 'chemical' && sortDir === 'asc'
									? faCaretUpFilled
									: faCaretUp
							}
							className={`caret-up ${
								showSortCaret &&
								((hoveredButton === 'Chemical' &&
									sortBy !== 'chemical') ||
									(sortDir === 'asc' && sortBy === 'chemical'))
									? 'show'
									: 'hide'
							}`}
						/>
						<FontAwesomeIcon
							icon={faCaretDownFilled}
							className={`caret-down ${
								showSortCaret &&
								sortDir === 'desc' &&
								sortBy === 'chemical'
									? 'show'
									: 'hide'
							}`}
						/>
					</button>
					<button
						className={'btn position-relative'}
						onMouseEnter={(e) => setHoveredButton('Load')}
						onMouseLeave={() => setHoveredButton('')}
						onClick={(e) => {
							if (sortDir === 'desc' || sortBy !== 'load') {
								setSortDir('asc');
							} else {
								setSortDir('desc');
							}
							setSortBy('load');
						}}
					>
						<FontAwesomeIcon
							icon={faFunction}
							className={'sort-icon'}
						/>
						Load
						<FontAwesomeIcon
							icon={
								sortBy === 'load' && sortDir === 'asc'
									? faCaretUpFilled
									: faCaretUp
							}
							className={`caret-up ${
								showSortCaret &&
								((hoveredButton === 'Load' && sortBy !== 'load') ||
									(sortDir === 'asc' && sortBy === 'load'))
									? 'show'
									: 'hide'
							}`}
						/>
						<FontAwesomeIcon
							icon={faCaretDownFilled}
							className={`caret-down ${
								showSortCaret &&
								sortDir === 'desc' &&
								sortBy === 'load'
									? 'show'
									: 'hide'
							}`}
						/>
					</button>
					<button
						className={'btn position-relative'}
						onMouseEnter={(e) => setHoveredButton('Date')}
						onMouseLeave={() => setHoveredButton('')}
						onClick={(e) => {
							if (sortDir === 'desc' || sortBy !== 'date') {
								setSortDir('asc');
							} else {
								setSortDir('desc');
							}
							setSortBy('date');
						}}
					>
						<FontAwesomeIcon
							icon={faCalendarAlt}
							className={'sort-icon'}
						/>
						Date
						<FontAwesomeIcon
							icon={
								sortBy === 'date' && sortDir === 'desc'
									? faCaretUpFilled
									: faCaretUp
							}
							className={`caret-up ${
								showSortCaret &&
								((hoveredButton === 'Date' && sortBy !== 'date') ||
									(sortDir === 'asc' && sortBy === 'date'))
									? 'show'
									: 'hide'
							}`}
						/>
						<FontAwesomeIcon
							icon={faCaretDownFilled}
							className={`caret-down ${
								showSortCaret &&
								sortDir === 'desc' &&
								sortBy === 'date'
									? 'show'
									: 'hide'
							}`}
						/>
					</button>
				</div>
			</div>
		</header>
	);
};

SdsFilesHeader.propTypes = {};

export default SdsFilesHeader;
