export const UserRoles = {
	admin: 'admin',
	moderator: 'moderator',
	user: 'internal-user',
	customerMaster: 'customer-master',
	customerAdmin: 'customer-admin',
	customerUser: 'customer-user',
	vendor: 'vendor'
};
//some views will be for admins only, some for users (non-admins)
// and then the rest is available for all roles
export const userRoles = {
	admins: [String(UserRoles.admin), String(UserRoles.moderator)],
	moderators: [String(UserRoles.moderator)],
	users: [String(UserRoles.user)],
	internalUsers: [
		String(UserRoles.admin),
		String(UserRoles.moderator),
		String(UserRoles.user)
	],
	customersAll: [
		String(UserRoles.customerAdmin),
		String(UserRoles.customerMaster),
		String(UserRoles.customerUser)
	],
	customerAdmins: [
		String(UserRoles.customerMaster),
		String(UserRoles.customerAdmin)
	],
	customerUsers: [String(UserRoles.customerUser)],
	vendors: [String(UserRoles.vendor)],
	all: [
		String(UserRoles.admin),
		String(UserRoles.moderator),
		String(UserRoles.user),
		String(UserRoles.customerMaster),
		String(UserRoles.customerAdmin),
		String(UserRoles.customerUser),
		String(UserRoles.vendor)
	]
};
