import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forgotPassword, login } from './API_Functions/UserFunctions';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import { useGlobalAuthActionsContext } from '../context/authContext';
import logo from '../img/ecgrow-logo-w.png';
import ResetPasswordForm from './ResetPasswordForm';

const Home = (props) => {
	const { form } = props;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [message, setMessage] = useState('');
	const [showLogin, setShowLogin] = useState(true);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [
		showForgotPasswordForm,
		setShowForgotPasswordForm
	] = useState(true);
	const [rememberMe, setRememberMe] = useState(false);
	let history = useHistory();

	useEffect(() => {
		setMessage('');
	}, [form]);

	const setAuthData = useGlobalAuthActionsContext();

	const onLoginSubmit = (e) => {
		e.preventDefault();

		const user = {
			email,
			password,
			rememberMe
		};

		// login(user)
		login(user)
			.then((res) => {
				if (res.user) {
					setAuthData((prevState) => {
						return {
							...prevState,
							loading: false,
							user: {
								email: res.user.email,
								type: res.user.type,
								customer: res.user.customer,
								first: res.user.first
							}
						};
					});

					if (
						res.user.type === 'admin' ||
						res.user.type === 'moderator'
					) {
						history.push('/regulatory', { loggingIn: true });
					} else {
						// setCustomerData({
						// 	...customerData,
						// 	selectedCustomer: {
						// 		...selectedCustomer,
						// 		master: masterRes.data.master || {},
						// 		users:
						// 			userRes.data.users.sort((a, b) => {
						// 				return a.type > b.type;
						// 			}) || []
						// 	}
						// });

						history.push('/sds-files');
					}
				} else {
					setError(res);
				}
			})
			.catch((e) => console.log('Error logging in!'));
	};

	const onPasswordResetSubmit = (e) => {
		e.preventDefault();
		forgotPassword(email)
			.then((res) => {
				setMessage(res.message);
				setEmail('');
				setShowForgotPasswordForm(false);
			})
			.catch((e) => console.log(e));
	};

	const showPasswordReset = (e) => {
		// e.preventDefault();
		setShowLogin(false);
		setShowForgotPassword(true);
		setError('');
	};

	const showLoginForm = (e) => {
		e.preventDefault();
		setShowForgotPassword(false);
		setShowLogin(true);
		setError('');
	};

	return (
		<div className={'wrap background-img login-page'}>
			<header>
				<img src={logo} alt={'EC Grow'} />
			</header>
			<div className={'login'}>
				<div className={'login-wrap'}>
					<div className={'shadow-wrap'}>
						<div className={'login'}>
							<h1 className={'title text-center'}>
								DOCUMENT PORTAL LOGIN
							</h1>
							{error && (
								<h5
									className={'alert alert-danger text-center'}
									role={'alert'}
								>
									{error}
								</h5>
							)}
							{message && (
								<h5
									className={'alert alert-info text-center'}
									role={'alert'}
								>
									{message}
								</h5>
							)}
							<div className="row">
								<div className="col-md-10 mt-3 mx-auto">
									{/*{transitions.map(({ item, key, props }) =>*/}
									{/*	item ? (*/}
									{/*		<animated.div key={key}>*/}
									{form === 'login' && (
										<LoginForm
											onLoginSubmit={onLoginSubmit}
											email={email}
											setEmail={setEmail}
											password={password}
											setPassword={setPassword}
											showPasswordReset={showPasswordReset}
											rememberMe={rememberMe}
											setRememberMe={setRememberMe}
										/>
									)}
									{form === 'forgotPassword' && (
										<ForgotPasswordForm
											email={email}
											setEmail={setEmail}
											onPasswordResetSubmit={onPasswordResetSubmit}
											showForgotPasswordForm={showForgotPasswordForm}
										/>
									)}
									{form === 'resetPassword' && (
										<ResetPasswordForm
											match={props.match.params.hash}
											create={false}
										/>
									)}
									{form === 'createPassword' && (
										<ResetPasswordForm
											match={props.match.params.hash}
											create={true}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
