import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

UserSettingsDropdown.propTypes = {};

function UserSettingsDropdown(props) {
	const { logout, userType, onClose } = props;
	const ref = useRef(null);
	const escapeListener = useCallback(
		(e) => {
			if (e.key === 'Escape') {
				onClose();
			}
		},
		[onClose]
	);
	const clickListener = useCallback(
		(e) => {
			if (!ref.current?.contains(e.target)) {
				onClose && onClose(); // using optional chaining here, change to onClose && onClose(), if required
			}
		},
		[onClose]
	);

	// Below is the 10 lines of code you need.
	useEffect(() => {
		// Attach the listeners on component mount.
		document.addEventListener('click', clickListener);
		document.addEventListener('keyup', escapeListener);
		// Detach the listeners on component unmount.
		return () => {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		};
	}, [clickListener, escapeListener]);

	return (
		<div
			className={
				'user-settings-dropdown bg-dark border p-2 d-flex flex-column'
			}
			ref={ref}
		>
			<div className={'text-center mb-3'}>
				User Type:
				<br /> {userType}
			</div>
			<button
				className={
					'btn justify-content-center align-self-center text-center mx-auto'
				}
				onClick={logout}
			>
				<FontAwesomeIcon
					className={'user-settings-icon'}
					icon={faUser}
					size={'2x'}
				/>
				Logout
			</button>
		</div>
	);
}

UserSettingsDropdown.propTypes = {
	logout: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default UserSettingsDropdown;
