import React, { Component } from 'react';

const colors = { white: '#FFFFFF', black: '#000000' };
const sizes = { big: ' la-3x', medium: ' la-2x', small: '' };

const LoadingApp = (props) => {
	const {
		spinnerColor = 'white',
		size = 'big',
		bgColor = 'black'
	} = props;
	return (
		<div className={`page-cover ${bgColor}`}>
			<div
				style={{ color: colors[spinnerColor] }}
				className={`la-ball-clip-rotate-multiple${sizes[size]}`}
			>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default LoadingApp;
