import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import './styles/index.css';
import './styles/App.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalCustomerContextProvider from './context/customerContext';
import GlobalAuthContextProvider from './context/authContext';
import GlobalModalContextProvider from './context/modalContext';
import { BrowserRouter } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
	<AlertProvider
		containerStyle={{ zIndex: 1010 }}
		template={AlertTemplate}
	>
		<GlobalAuthContextProvider>
			<GlobalModalContextProvider>
				<GlobalCustomerContextProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</GlobalCustomerContextProvider>
			</GlobalModalContextProvider>
		</GlobalAuthContextProvider>
	</AlertProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
