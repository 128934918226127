export const AuthRoutes = {
	siteAdmin: '/site-admin',
	customer: '/customer',
	customerAdmin: '/customer-admin',
	SDSFiles: '/sds-files',
	SDSFilesModal: '/sds-files-modal',
	regulatory: '/regulatory'
};

export const NonAuthRoutes = {
	root: '/',
	login: '/',
	forgotPassword: '/forgotPassword',
	resetPassword: '/resetPassword/:hash',
	createPassword: '/createPassword/:hash',
	unauthorized: '/unauthorized'
};
