import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = (props) => {
	const {
		email,
		setEmail,
		onPasswordResetSubmit,
		showForgotPasswordForm
	} = props;

	return (
		<div>
			{showForgotPasswordForm ? (
				<form noValidate onSubmit={onPasswordResetSubmit}>
					<h1 className="h3 mb-3 font-weight-normal">
						Forgot Password?
					</h1>
					<div className="form-group mb-2">
						<label htmlFor="email">Email address</label>
						<input
							type="email"
							className="form-control"
							name="email"
							placeholder="Enter email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
					>
						Send reset email
					</button>
					<Link className="btn btn-lg btn-danger me-2" to={'/'}>
						Cancel
					</Link>
				</form>
			) : (
				<h4>
					Proceed to <a href={'/'}>Login</a>
				</h4>
			)}
		</div>
	);
};

ForgotPasswordForm.propTypes = {
	email: PropTypes.string.isRequired,
	setEmail: PropTypes.func.isRequired,
	onPasswordResetSubmit: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
