const port = process.env.REACT_APP_PORT || 3001;

const EnvVars = {
	port,
	baseURL:
		process.env.REACT_APP_NODE_ENV === 'development' ? '' : '/api',
	sdsLocation: process.env.REACT_APP_SDS_LOCATION
};

module.exports = EnvVars;
