import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';

const CloseButtonComp = styled.button`
	position: absolute;
	color: #dddddd;
	top: 0;
	right: 0;
	border: none;
	background: transparent;
	padding-right: 5px;
	&:hover {
		cursor: pointer;
		color: #eeeeee;
	}
`;

const CloseButton = (props) => {
	const { size, dark } = props;
	return (
		<CloseButtonComp
			onClick={() => {
				props.setOpenModal(false);
			}}
		>
			<FontAwesomeIcon
				className={'close-modal'}
				name={'window-close'}
				icon={faWindowClose}
				size={size || '1x'}
				color={`${dark === 'true' ? '#999999' : '#DDDDDD'}`}
			/>
		</CloseButtonComp>
	);
};

CloseButton.propTypes = {
	setOpenModal: PropTypes.func
};

export default CloseButton;
