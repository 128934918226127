import axios from 'axios';

let { baseURL } = require('./EnvVars');

export const addFilesToNewCompany = (updateDBData) => {
	return axios
		.post(baseURL + '/SDS/addFilesToNewCompany', {
			updateDBData
		})
		.then((response) => {
			return response;
		})
		.catch((e) => {
			return Promise.reject(e);
		});
};

export const checkForSDSDuplicates = (filenames, fullFilenames) => {
	return axios
		.get(baseURL + '/SDS/checkForDuplicates', {
			params: { filenames }
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			// console.log('got back: ', err);
			if (err.response) {
				return err.response.data;
			} else if (err.request) {
				console.log('catch req: ', err.request);
			} else {
				console.log(err);
			}
			return Promise.reject(err);
		});
};

export const deleteSDSFiles = async (filenames, customer) => {
	return await axios
		.patch(baseURL + '/SDS/deleteSDSFiles', {
			filenames,
			customer
		})
		.catch((e) => console.log(e));
};

export const updateSDSForAllCompaniesAsync = async (
	SDS,
	companies
) => {
	return axios
		.post(baseURL + '/SDS/updateSDSForAllCompanies', {
			SDS,
			companies
		})
		.then((response) => {
			return response;
		})
		.catch((e) => {
			return Promise.reject(e);
		});
};

export const uploadFiles = (data, setLoaded) => {
	const config = {
		onUploadProgress: function (progressEvent) {
			const percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setLoaded(percentCompleted);
		},
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};

	return axios
		.post(baseURL + '/SDS/upload', data, config)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			// console.log('got back: ', err);
			if (err.response) {
				return err.response.data;
			} else if (err.request) {
				console.log('catch req: ', err.request);
			} else {
				console.log(err);
			}
			return Promise.reject(err);
		});
};

export const updateAndUploadSDSFiles = async (updateDBData) => {
	// const theFile = updateDBData.file;
	// delete updateDBData.file;
	// update all in the DB
	const files = updateDBData.files;
	delete updateDBData.files;
	// console.log({ files });
	const updatedFiles = (
		await axios.post(baseURL + '/SDS/updateAddSDSFiles', updateDBData)
	).data.updatedFiles;
	// console.log({ updatedFiles });
	// now upload the new file(s) with each of the filenames
	let promises = [];
	console.log({ files });
	files.forEach((file) => {
		const filename = file.name;
		const sheetName = updatedFiles.uploadFiles[filename];
		// console.log(filename, sheetName);
		// 	console.log({ file });
		const data = new FormData();
		// 	data.append('SDSName', updateDBData.SDSName);
		// 	data.append('customer', updateDBData.customer);
		data.append('pdfFilename', sheetName);
		// 	data.append('update', 'true');
		// 	data.append('onlyThisCompany', 'false');
		data.append('file', file);
		promises.push(
			axios
				.post(baseURL + '/SDS/upload', data, null)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					if (err.response) {
						return err.response.data;
					} else if (err.request) {
						//console.log('catch req: ', err.request);
					} else {
						//console.log(err);
					}
					return Promise.reject(err);
				})
		);
	});
	return Promise.all(promises).then(() => {
		delete updatedFiles.uploadFiles;
		return { status: 200, updatedFiles };
	});
	// upload the new one to all the companies
	// return axios
	// 	.post(baseURL + '/SDS/upload', data, null)
	// 	.then((response) => {
	// 		return response;
	// 	})
	// 	.catch((err) => {
	// 		// console.log('got back: ', err);
	// 		if (err.response) {
	// 			return err.response.data;
	// 		} else if (err.request) {
	// 			console.log('catch req: ', err.request);
	// 		} else {
	// 			console.log(err);
	// 		}
	// 		return Promise.reject(err);
	// 	});
};

export const loadFilesIntoDB = (fileInfo) => {
	return axios
		.post('/SDS/loadFilesIntoDB')
		.then((response) => {
			return response;
		})
		.catch((e) => {
			return Promise.reject(e);
		});
};

// export const addFileInfoToDB = (fileInfo) => {
// 	return axios
// 		.post(baseURL + '/SDS/addFileInfoToDB', { fileInfo })
// 		.then((res) => {
// 			return res;
// 		})
// 		.catch((e) => {
// 			return Promise.reject(e);
// 		});
// };
