import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';
import { resetPassword } from './API_Functions/UserFunctions';
import { Link, useHistory } from 'react-router-dom';

const ResetPasswordForm = (props) => {
	const { match, create } = props;
	const [error, setError] = useState('');
	const [showExpiredError, setShowExpiredError] = useState(false);
	const [requirementsClass, setRequirementsClass] = useState('');
	const [passwordReset, setPasswordReset] = useState(false);
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	const passwordsMatch = password1 === password2;
	let history = useHistory();

	const clearPasswords = () => {
		setPassword1('');
		setPassword2('');
	};

	const resetPasswordAPI = (e) => {
		e.preventDefault();
		const regex = new RegExp(
			'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
		);
		if (!passwordsMatch) {
			setError('Please make sure both passwords match.');
			clearPasswords();
		} else if (!regex.test(password1)) {
			setError(
				'Please make sure your password meets the minimum requirements below.'
			);
			setRequirementsClass('highlighted');
			//clearPasswords();
		} else {
			resetPassword(match, password1)
				.then((res) => {
					if (res.status === 401) {
						if (res.data.error === 'reset link expired') {
							setShowExpiredError(true);
						} else {
							setError(
								'Unable to reset password.  Please try again.'
							);
						}
					} else if (res.status === 200) {
						setPasswordReset(true);
						setPassword1('');
						setPassword2('');
						// forward to login page after 3 seconds
						setTimeout(function () {
							history.push('/');
						}, 3000);
					}
				})
				.catch((e) => console.log(e));
		}
	};

	const changePassword1 = (e) => {
		setRequirementsClass('');
		setError('');
		setPassword1(e.target.value);
	};

	return (
		<form className={'resetForm'}>
			{showExpiredError && (
				<h4
					className={'alert alert-danger text-center'}
					role={'alert'}
				>
					Your link to reset password is expired, please{' '}
					<a href="/forgotPassword">request a new one</a>
				</h4>
			)}
			{error ? (
				<h3
					className={'alert alert-danger text-center'}
					role={'alert'}
				>
					{error}
				</h3>
			) : (
				<h3> </h3>
			)}
			{passwordReset && (
				<h3 className={'alert alert-into text-center'} role={'alert'}>
					Password successfully reset. Forwarding to login page in 3
					seconds otherwise <a href={'/'}>click here</a> to go there.
				</h3>
			)}
			{!passwordReset && (
				<>
					<h1 className="h3 mb-3 font-weight-normal">
						{create ? 'Create Password' : 'Reset Password'}
					</h1>
					<p
						className={`small text-dimmed p-2${
							requirementsClass !== '' ? ' highlighted' : ''
						}`}
					>
						Minimum 8 characters, must include uppercase, lowercase,
						and numbers.
					</p>
					<div className="form-group">
						<label htmlFor="password1">New Password</label>
						<div className={'row no-gutters'}>
							<input
								type="password"
								className="form-control col-8"
								name="password1"
								placeholder="Enter password"
								value={password1}
								onChange={(e) => changePassword1(e)}
							/>
							{password2 !== '' && !passwordsMatch && (
								<div className={'col-3 matching text-danger'}>
									Not matching
								</div>
							)}
							{password2 !== '' && passwordsMatch && (
								<div className={'col-3 matching text-success'}>
									Matching
								</div>
							)}
						</div>
						<div className={'row no-gutters'}>
							<PasswordStrengthBar
								className={'col-8'}
								password={password1}
								minLength={8}
							/>
						</div>
					</div>
					<div className="form-group">
						<label htmlFor="password2">Confirm New Password</label>
						<div className={'row no-gutters'}>
							<input
								type="password"
								className="form-control col-8"
								name="password2"
								placeholder="Confirm password"
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
							/>
							{password2 !== '' && !passwordsMatch && (
								<div className={'col-3 matching text-danger'}>
									Not matching
								</div>
							)}
							{password2 !== '' && passwordsMatch && (
								<div className={'col-3 matching text-success'}>
									Matching
								</div>
							)}
						</div>
						<div className={'row no-gutters'}>
							<PasswordStrengthBar
								className={'col-8'}
								password={password2}
								minLength={8}
							/>
						</div>
					</div>
					<button
						type={'submit'}
						className={'btn btn-lg btn-primary me-3'}
						onClick={resetPasswordAPI}
					>
						Reset Password
					</button>
					<Link className={'btn btn-lg btn-danger me-2'} to={'/'}>
						Cancel
					</Link>
				</>
			)}
		</form>
	);
};

ResetPasswordForm.propTypes = {
	match: PropTypes.string.isRequired
};

export default ResetPasswordForm;
